import React from 'react';
import { Text } from '@lbc-toolkit/text';

function ChartTooltip({ point }) {
    return (
        <div
            style={{
                backgroundColor: '#ffffff',
                padding: '8px',
                boxShadow: '1px 1px 0px #E6E5E3, -1px 0px 0px #E6E5E3',
                minWidth: 'max-content'
            }}
        >
            <Text type="h5">
                {point.data.yFormatted.toFixed(1)} t @{' '}
                {point.data.xFormatted.toFixed(1)} m
            </Text>
        </div>
    );
}

export default ChartTooltip;
