import * as types from './types';

export const setMinCapacity = minCapacity => ({
    type: types.SET_MIN_CAPACITY,
    minCapacity
});

export const setMaxCapacity = maxCapacity => ({
    type: types.SET_MAX_CAPACITY,
    maxCapacity
});

export const setMaxRadius = maxRadius => ({
    type: types.SET_MAX_RADIUS,
    maxRadius
});

export const setMaxCapacityAtMax = maxCapacityAtMax => ({
    type: types.SET_MAX_CAPACITY_AT_MAX,
    maxCapacityAtMax
});

export const setMaxHeight = maxHeight => ({
    type: types.SET_MAX_HEIGHT,
    maxHeight
});

export const addBase = base => ({
    type: types.ADD_BASE,
    base
});

export const removeBase = base => ({
    type: types.REMOVE_BASE,
    base
});

export const setBase = base => ({
    type: types.SET_BASE,
    base
});

export const setCraneType = craneType => ({
    type: types.SET_CRANE_TYPE,
    craneType
});

export const setTower = tower => ({
    type: types.SET_TOWER,
    tower
});
