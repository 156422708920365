import { useEffect, useState } from 'react';
import {
  getCraneModel,
  getCraneModelFileName
} from '../services/craneModelService';
import mapCraneModelRequest from '../tools/mapCraneModelRequest';

export function useCraneModel(crane, format) {
  const [storageUrl, setStorageUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingBlobUrl, setLoadingBlobUrl] = useState(false);
  const [loadingStorageUrl, setLoadingStorageUrl] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (crane) {
      const craneModelRequest = mapCraneModelRequest(crane, format);
      setLoadingBlobUrl(true);
      getCraneModel(craneModelRequest).then(url => {
        setSuccess(/((http|https):\/\/)/.test(url));
        setLoadingBlobUrl(false);
      });
      setLoadingStorageUrl(true);
      getCraneModelFileName(craneModelRequest).then(fileName => {
        setStorageUrl(
          `${process.env.REACT_APP_STORAGE_MODEL_URL}/${fileName}.${format}?${process.env.REACT_APP_STORAGE_SAS}`
        );
        setLoadingStorageUrl(false);
      });
    }
  }, [crane, format]);

  useEffect(() => {
    if (!loadingBlobUrl && !loadingStorageUrl) {
      setStorageUrl(success ? storageUrl : '');
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [storageUrl, loadingBlobUrl, loadingStorageUrl, success]);

  return [storageUrl, loading, success];
}
