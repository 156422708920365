import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loginUser } from 'redux/user/actions';

class Login extends React.Component {
  login = () => {
    const manager = this.props.manager;

    manager.events.addUserLoaded(user => {
      console.log('User loaded');
    });

    manager.events.addSilentRenewError(e => {
      console.error('Silent Renew Error', e.message);
    });

    manager.events.addAccessTokenExpired(() => {
      console.log('Access Token expired');
      this.silentRefresh();
    });

    manager.getUser().then(user => {
      if (!user || user.expired) {
        return manager.signinRedirect().catch(error => console.error(error));
      } else {
        this.props.actions.loginUser(user);
      }
    });
  };

  silentRefresh = () => {
    const manager = this.props.manager;

    manager
      .signinSilent()
      .then(user => console.log('User signed in'))
      .catch(error => console.log(error));
  };

  componentDidMount() {
    this.login();
  }

  render() {
    return <div />;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ loginUser }, dispatch)
  };
}

export { Login };

export default connect(null, mapDispatchToProps)(Login);
