import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Text } from '@lbc-toolkit/text';
import CraneCards from '../common/CraneCards';

const MAX_RESULTS = 60;

const propTypes = {
  cranes: PropTypes.array,
  loading: PropTypes.bool,
  onSelect: PropTypes.func,
  selectable: PropTypes.bool
};

const defaultProps = {
  cranes: [],
  loading: false,
  onSelect: () => {
    // do nothing...
  },
  selectable: true
};

export function ResultArea({ cranes, loading, onSelect, selectable }) {
  if (loading) {
    return <Text type="h4">Es wird nach passenden Kranmodellen gesucht.</Text>;
  }

  if (cranes.length < 1) {
    return <Text type="h4">Keine passenden Kranmodelle gefunden.</Text>;
  }

  if (cranes.length > MAX_RESULTS) {
    return (
      <>
        <Text type="h4">
          Suche weiter eingrenzen, zum Finden passender Kranmodelle.
        </Text>
        <Text type="h5">
          Es wurden {cranes.length} Krane zur gew&auml;hlten Parametereinstellung
          gefunden.
        </Text>
      </>
    );
  }

  return (
    <>
      <Text type="h5">
        Es wurden {cranes.length} Krane zur gew&auml;hlten Parametereinstellung
        gefunden.
      </Text>
      <CraneCards cranes={cranes} onSelect={onSelect} selectable={selectable} />
    </>
  );
}

ResultArea.propTypes = exact(propTypes);

ResultArea.defaultProps = defaultProps;

export default ResultArea;
