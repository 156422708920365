import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Dropdown } from '@lbc-toolkit/dropdown';
import { MultiSelect } from '@lbc-toolkit/multiselect';
import { Grid, GridItem } from '@lbc-toolkit/grid';
import * as filterSelectors from '../../redux/craneFilter/selectors';
import * as filterActions from '../../redux/craneFilter/actions';

const cranetypeOptions = [
  { label: 'EC-B', value: 'ecb' },
  { label: 'EC-H', value: 'ech' }
];

const cranebaseOptions = [
  { label: 'Unterwagen, nicht fahrbar (8m)', value: '21 HC 290 UC-0800' },
  { label: 'Unterwagen, nicht fahrbar (6m)', value: '21 HC 290 UC-0600' },
  { label: 'Fundamentkreuz', value: '21 HC 290 CB-0450' },
  { label: 'Fundamentanker', value: '21 HC 290 FA' }
];

const cranetowerOptions = [{ label: '21 HC 290', value: '21 HC 290' }];

const filterOptions = [{ label: 'Sortiert nach: Relevanz', value: '0' }];

const propTypes = {
  actions: PropTypes.object.isRequired,
  base: PropTypes.array.isRequired,
  craneType: PropTypes.array.isRequired,
  tower: PropTypes.array.isRequired
};

export function ExtendedFilterArea({ actions, base, craneType, tower }) {
  return (
    <Grid columns="1fr 2fr 1fr 1fr" padding="32px 0" gap="32px">
      <GridItem>
        <MultiSelect
          placeholder="Alle Krantypen"
          options={cranetypeOptions}
          selected={craneType}
          onSelect={x =>
            x ? actions.setCraneType(x) : actions.setCraneType([])
          }
          width="100%"
        />
      </GridItem>
      <GridItem>
        <MultiSelect
          placeholder="Alle Kranbasistypen"
          options={cranebaseOptions}
          selected={base}
          onSelect={x => (x ? actions.setBase(x) : actions.setBase([]))}
          width="100%"
        />
      </GridItem>
      <GridItem>
        <MultiSelect
          placeholder="Alle Turmsysteme"
          options={cranetowerOptions}
          selected={tower}
          onSelect={x => (x ? actions.setTower(x) : actions.setTower([]))}
          width="100%"
        />
      </GridItem>
      <GridItem>
        <Dropdown
          options={filterOptions}
          selected="0"
          width="100%"
          onSelect={() => {}}
        />
      </GridItem>
    </Grid>
  );
}

ExtendedFilterArea.propTypes = exact(propTypes);

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...filterActions }, dispatch)
  };
}

function mapStateToProps(state) {
  return {
    base: filterSelectors.base(state),
    craneType: filterSelectors.craneType(state),
    tower: filterSelectors.tower(state)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExtendedFilterArea);
