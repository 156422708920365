import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Grid, GridItem } from '@lbc-toolkit/grid';
import CraneDetailsPageTitle from '../CraneDetailsPageTitle';
import DetailArea from '../DetailArea';
import { ModelViewer } from '@lbc-toolkit/modelviewer';
import dummyObj from '../../../media/obj/dummy.obj';
import getBaseUrl from 'get-base-url';
import { useCraneModel } from '../../../hooks/useCraneModel';

const dummyObjFileName = String(dummyObj).split('/').pop();
const mediaUrl = getBaseUrl() + 'static/media/';
const dummyModelPath = `${mediaUrl}${dummyObjFileName}`;

const propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  staticContext: PropTypes.object
};

const defaultProps = {
  match: {},
  history: {},
  location: {},
  staticContext: {}
};

export function CraneDetailsPage({ location }) {
  const crane =
    location.state && location.state.crane ? location.state.crane : {};
  const takeoverUrl = location.state.takeoverUrl
    ? location.state.takeoverUrl
    : undefined;
  const [storageUrl, loading, success] = useCraneModel(crane, 'obj');

  return (
    <Grid
      columns="repeat(12, 1fr)"
      rows="auto 1fr"
      backgroundColor="#ffffff"
      height="100vh"
    >
      <GridItem area="1/2/1/12">
        <CraneDetailsPageTitle title="Krandetails" />
      </GridItem>
      <GridItem area="2/2/2/9">
        <DetailArea crane={crane} takeoverUrl={takeoverUrl}/>
      </GridItem>
      <GridItem area="2/9/2/13" padding="0 0 0 64px">
        {!loading && (
          <ModelViewer
            url={success ? storageUrl : dummyModelPath}
            enableFlyAnimation
            enableRotateAnimation
          />
        )}
      </GridItem>
    </Grid>
  );
}

CraneDetailsPage.propTypes = exact(propTypes);

CraneDetailsPage.defaultProps = defaultProps;

export default CraneDetailsPage;
