import { NAVIGATION } from 'consts/navigation';
import produce from 'immer';
import * as types from './types';

function determineInitialId() {
  if (
    window.location.pathname === NAVIGATION.newConfiguration.routes.START ||
    window.location.pathname === NAVIGATION.newConfiguration.routes.SMART
  ) {
    return NAVIGATION.newConfiguration.routeId;
  }
  if (
    NAVIGATION.myCranes.routes.MYCRANES === window.location.pathname ||
    NAVIGATION.myCranes.routes.CRANEDETAILS === window.location.pathname
  ) {
    return NAVIGATION.myCranes.routeId;
  }
  return NAVIGATION.main.routeId;
}

const initialState = {
  id: determineInitialId()
};

export default produce((draft, action) => {
  switch (action.type) {
    case types.NAVIGATE_TO_HOME:
      draft.id = 0;
      return;
    case types.NAVIGATE_TO_CONFIG_START:
    case types.NAVIGATE_TO_SMART_CONFIG:
      draft.id = 1;
      return;
    case types.NAVIGATE_TO_MYCRANES:
    case types.NAVIGATE_TO_CRANE_DETAILS:
      draft.id = 2;
      return;
    default:
      return;
  }
}, initialState);
