import React from 'react';
import { Flex, FlexItem } from '@lbc-toolkit/flex';
import { getCraneModel } from '../../../services/craneModelService';
import mapCraneModelRequest from '../../../tools/mapCraneModelRequest';
import DummyZip from '../../../media/obj/dummy.zip';
import DummyIfc from '../../../media/ifc/dummy.ifc';
import DownloadButton from './DownloadButton';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

const propTypes = {
  crane: PropTypes.object.isRequired,
  takeoverUrl: PropTypes.string
};

const defaultProps = {
  takeoverUrl: undefined
};

const DUMMY_ZIP_FILENAME = 'Example.zip';
const DUMMY_IFC_FILENAME = 'Example.ifc';

function DownloadButtons({ crane, takeoverUrl }) {
  const callLink = (filename, url) => {
    let link = document.createElement('a');
    link.href = url;
    if (filename) {
      link.download = filename;
    }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link = null;
  };

  const handleDownloadZipOrObj = () => {
    let craneModelRequest = mapCraneModelRequest(crane, 'zip');
    getCraneModel(craneModelRequest).then(url => {
      if (!url) {
        craneModelRequest = mapCraneModelRequest(crane, 'obj');
        getCraneModel(craneModelRequest).then(url => {
          if (!url) {
            callLink(DUMMY_ZIP_FILENAME, DummyZip);
          } else {
            callLink(`${crane.name}.obj`, url);
          }
        });
      } else {
        callLink(`${crane.name}.zip`, url);
      }
    });
  };

  const handleDownloadIfc = () => {
    const craneModelRequest = mapCraneModelRequest(crane, 'IFC');
    getCraneModel(craneModelRequest).then(url => {
      if (!url) {
        callLink(DUMMY_IFC_FILENAME, DummyIfc);
      } else {
        callLink(`${crane.name}.ifc`, url);
      }
    });
  };

  const handleTransmitConfiguration = () => {
    const craneModelRequest = mapCraneModelRequest(crane, 'IFC');
    const searchParams = new URLSearchParams();
    getCraneModel(craneModelRequest).then(url => {
      let craneUrl;
      if (!url) {
        craneUrl = `${window.location.origin}${DummyIfc}`;
      } else {
        craneUrl = url;
      }
      searchParams.append('crane_url', craneUrl);
      callLink(undefined, `${takeoverUrl}?${searchParams.toString()}`);
    });
  };

  return (
    <Flex flow="row" justifyContent="flex-end">
      <FlexItem>
        <DownloadButton
          type="light"
          textFirstLine="3D-Modell"
          textSecondLine="(ZIP/OBJ)"
          onClick={handleDownloadZipOrObj}
        />
      </FlexItem>
      <FlexItem margin="0 0 0 24px">
        <DownloadButton
          type={!takeoverUrl ? 'primary' : 'light'}
          textFirstLine="BIM-Modell"
          textSecondLine="(IFC)"
          onClick={handleDownloadIfc}
        />
      </FlexItem>
      {takeoverUrl && (
        <FlexItem margin="0 0 0 24px">
          <DownloadButton
            type="primary"
            textFirstLine="Konfiguration"
            textSecondLine="übertragen"
            onClick={handleTransmitConfiguration}
          />
        </FlexItem>
      )}
    </Flex>
  );
}

DownloadButtons.propTypes = exact(propTypes);

DownloadButtons.defaultProps = defaultProps;

export default DownloadButtons;
