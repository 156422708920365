import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { Button } from '@lbc-toolkit/button';
import { Grid, GridItem } from '@lbc-toolkit/grid';
import { Text } from '@lbc-toolkit/text';
import { Searchbox } from '@lbc-toolkit/searchbox';

const propTypes = {
  title: PropTypes.string.isRequired
};

function MyCranesPageTitle({ title }) {
  return (
    <Grid columns="auto 1fr auto" padding="24px 0">
      <GridItem>
        <Text type="h2">{title}</Text>
      </GridItem>
      <GridItem justifySelf="center">
        <Searchbox
          placeholder="Suche nach Krantyp, Stichworten, etc."
          width="500px"
          onChange={() => {}}
          onSearch={() => {}}
          onEnterPress={() => {}}
        />
      </GridItem>
      <GridItem>
        <Button type="radial" icon={faQuestionCircle} />
      </GridItem>
    </Grid>
  );
}

MyCranesPageTitle.propTypes = exact(propTypes);

export default MyCranesPageTitle;
