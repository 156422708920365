import produce from 'immer';
import * as types from './types';

const initialState = {
  user: {
    name: '',
    initials: '',
    hasAccess: false
  }
};

export default produce((draft, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      draft.user = action.payload;
      return;
    case types.LOGOUT_SUCCESS:
      draft.user.name = '';
      draft.user.initials = '';
      draft.user.hasAccess = false;
      return;
    default:
      return;
  }
}, initialState);
