import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Line } from '@nivo/line';
import { AutoSizer } from 'react-virtualized';
import ChartTooltip from './ChartTooltip';

const chartConfig = {
    xScale: { type: 'linear', min: 0, max: 'auto' },
    yScale: { type: 'linear', min: 0, max: 'auto' },
    curve: 'basis',
    enablePoints: false,
    enableGridX: true,
    gridXValues: [0],
    gridYValues: [0, 2, 4, 6, 8, 10, 12],
    margin: { top: 25, right: 10, bottom: 50, left: 50 },
    colors: { datum: 'color' },
    borderColor: { from: 'color', modifiers: [['darker', 1.6]] },
    axisTop: null,
    axisLeft: {
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        tickValues: 5,
        legend: 't',
        legendPosition: 'middle',
        legendOffset: -30
    },
    axisBottom: {
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        tickValues: 5,
        legend: 'm',
        legendPosition: 'middle',
        legendOffset: 30
    },
    axisRight: null,
    useMesh: true,
    enableLabel: false,
    legends: [],
    animate: false,
    motionStiffness: 90,
    motionDamping: 15
};

const propTypes = {
    data: PropTypes.object.isRequired
};

function CapacityCurveChart({ data }) {
    const chartData = [
        {
            id: 'capacity',
            color: '#fdc400',
            data: Object.keys(data).map(key => {
                return {
                    x: Number(key),
                    y: Number((data[key] / 1000).toFixed(1))
                };
            })
        }
    ];

    chartConfig.xScale.max =
        chartData[0].data[chartData[0].data.length - 1].x + 5;
    chartConfig.yScale.max = chartData[0].data[0].y + 1;

    return (
        <AutoSizer defaultHeight={100} defaultWidth={100}>
            {({ width, height }) => (
                <Line
                    height={height}
                    width={width}
                    data={chartData}
                    tooltip={x => <ChartTooltip point={x.point} />}
                    {...chartConfig}
                />
            )}
        </AutoSizer>
    );
}

CapacityCurveChart.propTypes = exact(propTypes);

export default CapacityCurveChart;
