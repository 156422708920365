export const SET_MIN_CAPACITY = 'craneFilter/SET_MIN_CAPACITY';
export const SET_MAX_CAPACITY = 'craneFilter/SET_MAX_CAPACITY';
export const SET_MAX_RADIUS = 'craneFilter/SET_MAX_RADIUS';
export const SET_MAX_CAPACITY_AT_MAX = 'craneFilter/SET_MAX_CAPACITY_AT_MAX';
export const SET_MAX_HEIGHT = 'craneFilter/SET_MAX_HEIGHT';
export const ADD_BASE = 'craneFilter/ADD_BASE';
export const REMOVE_BASE = 'craneFilter/REMOVE_BASE';
export const SET_BASE = 'craneFilter/SET_BASE';
export const SET_CRANE_TYPE = 'craneFilter/SET_CRANE_TYPE';
export const SET_TOWER = 'craneFilter/SET_TOWER';
