import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Flex, FlexItem } from '@lbc-toolkit/flex';
import { Grid, GridItem } from '@lbc-toolkit/grid';
import { Text } from '@lbc-toolkit/text';
import InfoTooltip from './InfoTooltip';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import Spacer from '../common/Spacer';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const propTypes = {
  title: PropTypes.string,
  imageLeft: PropTypes.string,
  imageRight: PropTypes.string,
  tooltip: PropTypes.string,
  unit: PropTypes.shape({
    first: PropTypes.oneOf(['m', 't']),
    second: PropTypes.oneOf(['m', 't'])
  }),
  value: PropTypes.shape({
    first: PropTypes.number,
    second: PropTypes.number
  }),
  minValue: PropTypes.shape({
    first: PropTypes.number,
    second: PropTypes.number
  }),
  maxValue: PropTypes.shape({
    first: PropTypes.number,
    second: PropTypes.number
  }),
  step: PropTypes.shape({
    first: PropTypes.number,
    second: PropTypes.number
  }),
  onChange: PropTypes.shape({
    first: PropTypes.func,
    second: PropTypes.func
  })
};

const defaultProps = {
  title: 'Placeholder',
  imageLeft: '',
  imageRight: '',
  tooltip: '',
  unit: { first: 'm', second: 't' },
  value: { first: 0, second: 0 },
  minValue: { first: 0, second: 0 },
  maxValue: { first: 100, second: 100 },
  step: { first: 1, second: 1 },
  onChange: { first: () => {}, second: () => {} }
};

function DualFilter({
  title,
  imageLeft,
  imageRight,
  tooltip,
  unit,
  value,
  minValue,
  maxValue,
  step,
  onChange
}) {
  const [firstValue, setFirstValue] = useState(value.first);
  const [secondValue, setSecondValue] = useState(value.second);
  const [isFirstValueOutOfRange, setIsFirstValueOutOfRange] = useState(false);
  const [isSecondValueOutOfRange, setIsSecondValueOutOfRange] = useState(false);

  useEffect(() => {
    isNaN(firstValue) ||
    firstValue < minValue.first ||
    firstValue > maxValue.first
      ? setIsFirstValueOutOfRange(true)
      : setIsFirstValueOutOfRange(false);

    isNaN(secondValue) ||
    secondValue < minValue.second ||
    secondValue > maxValue.second
      ? setIsSecondValueOutOfRange(true)
      : setIsSecondValueOutOfRange(false);
  }, [firstValue, secondValue, maxValue, minValue]);

  return (
    <Grid
      columns="1fr 1fr"
      gap="12px"
      justifyContent="stretch"
      justifyItems="center"
    >
      <GridItem area="1/1/1/3">
        <Flex alignItems="center">
          <Text type="pSmall">{title}</Text>
          <Spacer narrow />
          <InfoTooltip icon={faInfoCircle} text={tooltip} />
        </Flex>
      </GridItem>
      <GridItem>
        <img alt={title} src={imageLeft} />
      </GridItem>
      <GridItem>
        <img alt={title} src={imageRight} />
      </GridItem>
      <GridItem>
        <Flex>
          <FlexItem>
            <Text type="h5" color={isFirstValueOutOfRange ? 'red' : 'black'}>
              {isNaN(firstValue) ? '?' : firstValue} {unit.first}
            </Text>
          </FlexItem>
          {isFirstValueOutOfRange && (
            <FlexItem margin="0 0 0 8px">
              <InfoTooltip
                icon={faInfoCircle}
                text={`Der/Die übergebene/n Wert/e sind unzulässig. Minimum: ${minValue.first}, Maximum: ${maxValue.first}`}
              />
            </FlexItem>
          )}
        </Flex>
      </GridItem>
      <GridItem>
        <Flex>
          <FlexItem>
            <Text type="h5" color={isSecondValueOutOfRange ? 'red' : 'black'}>
              {isNaN(secondValue) ? '?' : secondValue} {unit.second}
            </Text>
          </FlexItem>
          {isSecondValueOutOfRange && (
            <FlexItem margin="0 0 0 8px">
              <InfoTooltip
                icon={faInfoCircle}
                text={`Der/Die übergebene/n Wert/e sind unzulässig. Minimum: ${minValue.second}, Maximum: ${maxValue.second}`}
              />
            </FlexItem>
          )}
        </Flex>
      </GridItem>
      <GridItem justifySelf="stretch">
        <Slider
          value={firstValue}
          min={minValue.first}
          max={maxValue.first}
          step={step.first}
          onChange={value => setFirstValue(value)}
          onAfterChange={onChange.first}
          handleStyle={{
            backgroundColor: 'black',
            border: 'none'
          }}
          trackStyle={{ backgroundColor: '#fdc400' }}
        />
      </GridItem>
      <GridItem justifySelf="stretch">
        <Slider
          value={secondValue}
          min={minValue.second}
          max={maxValue.second}
          step={step.second}
          onChange={value => setSecondValue(value)}
          onAfterChange={onChange.second}
          handleStyle={{
            backgroundColor: 'black',
            border: 'none'
          }}
          trackStyle={{ backgroundColor: '#fdc400' }}
        />
      </GridItem>
    </Grid>
  );
}

DualFilter.propTypes = exact(propTypes);

DualFilter.defaultProps = defaultProps;

export default DualFilter;
