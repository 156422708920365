import * as types from './types';

const callLoginUser = (userName, userInitials, allowAccess) => ({
  type: types.LOGIN_SUCCESS,
  payload: {
    name: userName,
    initials: userInitials,
    hasAccess: allowAccess
  }
});

const callLogoutUser = () => ({
  type: types.LOGOUT_SUCCESS
});

const getInitials = (givenName, surname) => {
  if (givenName && surname) return givenName[0] + surname[0];
  if (givenName) return givenName[0];
  if (surname) return surname[0];

  return '';
};

const isUserAllowedToAccess = user => {
  if (!user.profile.role) return false;
  if (Array.isArray(user.profile.role)) {
    return (
      user.profile.role.some(
        role =>
          role === process.env.REACT_APP_ACCESS_ROLE_NAME ||
          role === process.env.REACT_APP_ACCESS_ROLE_EXTERNAL_NAME
      )
    );
  } else {
    return (
      user.profile.role === process.env.REACT_APP_ACCESS_ROLE_NAME ||
      user.profile.role === process.env.REACT_APP_ACCESS_ROLE_EXTERNAL_NAME
    );
  }
};

export const loginUser = user => {
  const allowAccess = isUserAllowedToAccess(user);

  return dispatch => {
    const profile = user.profile;

    const name = profile.given_name + ' ' + profile.family_name;
    dispatch(
      callLoginUser(
        name,
        getInitials(profile.given_name, profile.family_name),
        allowAccess
      )
    );
  };
};

export const logoutUser = () => {
  return dispatch => {
    dispatch(callLogoutUser());
  };
};
