import produce from 'immer';
import * as types from './types';

const initialState = {
    cranes: [],
    meta: {
        state: types.CRANES_LOADING
    }
};

export default produce((draft, action) => {
    switch (action.type) {
        case types.CRANES_LOADING:
            draft.meta.state = types.CRANES_LOADING;
            return;
        case types.CRANES_SUCCESS:
            draft.cranes = action.payload;
            draft.meta.state = types.CRANES_SUCCESS;
            return;
        case types.CRANES_FAILURE:
            draft.meta.state = types.CRANES_FAILURE;
            return;
        case types.CRANES_ADD:
            draft.cranes.push(action.payload);
            draft.meta.state = types.CRANES_SUCCESS;
            return;
        case types.CRANES_UPDATE_TAGS:
            const index = draft.cranes.findIndex(
                crane => crane.id === action.payload.id
            );
            draft.cranes[index] = action.payload;
            draft.meta.state = types.CRANES_SUCCESS;
            return;
        default:
            return;
    }
}, initialState);
