import React, { useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@lbc-toolkit/button';
import { TextInput } from '@lbc-toolkit/textinput';
import { Grid, GridItem } from '@lbc-toolkit/grid';
import { Text } from '@lbc-toolkit/text';
import { clearCranes } from '../../../redux/selectedCranes/actions';
import { addCrane } from '../../../redux/myCranes/actions';
import { cranes, selectedCount } from '../../../redux/selectedCranes/selectors';
import styles from './TaggingArea.module.css';

const propTypes = {
  selectedCount: PropTypes.number.isRequired,
  selectedCranes: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  staticContext: PropTypes.object,
  dispatch: PropTypes.func
};

const defaultProps = {
  match: {},
  history: {},
  location: {},
  staticContext: {},
  dispatch: () => {}
};

export function TaggingArea({ actions, selectedCount, selectedCranes }) {
  const [tags, setTags] = useState('');

  let history = useHistory();

  const handleAddClick = () => {
    const tagList = tags.length > 0 ? tags.split(';') : [];

    for (let i = 0; i < selectedCranes.length; ++i) {
      actions.addCrane(selectedCranes[i], tagList);
    }
    actions.clearCranes();
    history.push('/mycranes');
  };

  return (
    <div className={styles.container}>
      <Grid columns="1fr 5fr 5fr 1fr" padding="16px 32px" gap="32px">
        <GridItem>
          <Button
            type="secondary"
            label="Auswahl aufheben"
            onClick={actions.clearCranes}
          />
        </GridItem>
        <GridItem alignSelf="center">
          <Text type="p">{selectedCount} Krane ausgewählt</Text>
        </GridItem>
        <GridItem>
          <TextInput
            placeholder="Stichworte hinzufügen, um Krane später einfacher wiederzufinden"
            value={tags}
            width="100%"
            onChange={value => setTags(value)}
          />
        </GridItem>
        <GridItem>
          <Button
            type="primary"
            label="Krane hinzufügen"
            onClick={handleAddClick}
          />
        </GridItem>
      </Grid>
    </div>
  );
}

TaggingArea.propTypes = exact(propTypes);

TaggingArea.defaultProps = defaultProps;

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        addCrane: addCrane,
        clearCranes: clearCranes
      },
      dispatch
    )
  };
}

function mapStateToProps(state) {
  return {
    selectedCount: selectedCount(state),
    selectedCranes: cranes(state)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TaggingArea);
