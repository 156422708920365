import React from 'react';
import { Grid, GridItem } from '@lbc-toolkit/grid';
import Filter from './Filter';
import DualFilter from './DualFilter';
import graphicHeight from 'media/images/graphic_attr_height.svg';
import graphicMaxCapacity from 'media/images/graphic_attr_maxcapacity.svg';
import graphicMaxCapacityAtMax from 'media/images/graphic_attr_maxcapacityatmax.svg';
import graphicMaxRadiusAtMax from 'media/images/graphic_attr_maxradiusatmax.svg';
import graphicRadius from 'media/images/graphic_attr_radius.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  setMinCapacity,
  setMaxCapacity,
  setMaxRadius,
  setMaxCapacityAtMax,
  setMaxHeight
} from 'redux/craneFilter/actions';
import {
  minCapacity,
  maxCapacity,
  maxRadius,
  maxCapacityAtMax,
  maxHeight
} from 'redux/craneFilter/selectors';

function FilterArea() {
  const dispatch = useDispatch();

  const handleMaxCapacityChange = range => {
    dispatch(setMinCapacity(range[0]));
    dispatch(setMaxCapacity(range[1]));
  };

  const handleMaxRadiusChange = value => {
    dispatch(setMaxRadius(value));
  };

  const handleAtMaxRadiusChange = value => {
    dispatch(setMaxCapacityAtMax({ ...maximumCapacityAtMax, radius: value }));
  };

  const handleAtMaxCapacityChange = value => {
    dispatch(setMaxCapacityAtMax({ ...maximumCapacityAtMax, capacity: value }));
  };

  const handleMaxHeightChange = value => {
    dispatch(setMaxHeight(value));
  };

  const minimumCapacity = useSelector(state => minCapacity(state));
  const maximumCapacity = useSelector(state => maxCapacity(state));
  const maximumRadius = useSelector(state => maxRadius(state));
  const maximumCapacityAtMax = useSelector(state => maxCapacityAtMax(state));
  const maximumHeight = useSelector(state => maxHeight(state));

  const maxCapacityFilter = (
    <Filter
      title="Max. Last"
      tooltip="Grenzen Sie hier den Lastbereich ein, in dem Sie die maximal zu Hebende Last eintragen."
      minValue={0}
      maxValue={20}
      step={0.1}
      image={graphicMaxCapacity}
      value={[minimumCapacity, maximumCapacity]}
      unit="t"
      range
      onChange={handleMaxCapacityChange}
    />
  );

  const maxRadiusFilter = (
    <Filter
      title="Max. Ausladung"
      tooltip="Geben Sie die ungefähr benötigte Auslegerlänge an."
      image={graphicRadius}
      value={maximumRadius}
      unit="m"
      onChange={handleMaxRadiusChange}
    />
  );

  const maxCapacityAtMaxFilter = (
    <DualFilter
      title="Last bei Ausladung"
      tooltip="Neben den max. Werten können Sie eine Last-Ausladungs-Kombination vorgeben."
      imageLeft={graphicMaxCapacityAtMax}
      imageRight={graphicMaxRadiusAtMax}
      value={{
        first: maximumCapacityAtMax.radius,
        second: maximumCapacityAtMax.capacity
      }}
      unit={{
        first: 'm',
        second: 't'
      }}
      maxValue={{ first: 100, second: 20 }}
      step={{ first: 1, second: 0.1 }}
      onChange={{
        first: handleAtMaxRadiusChange,
        second: handleAtMaxCapacityChange
      }}
    />
  );

  const maxHeightFilter = (
    <Filter
      title="Hakenhöhe"
      tooltip="Geben Sie die benötigte Hakenhöhe ein."
      image={graphicHeight}
      value={maximumHeight}
      unit="m"
      onChange={handleMaxHeightChange}
    />
  );

  return (
    <Grid columns="1fr 1fr 2fr 1fr" padding="0 0 32px 0" gap="32px">
      <GridItem textAlign="center">{maxCapacityFilter}</GridItem>
      <GridItem textAlign="center">{maxRadiusFilter}</GridItem>
      <GridItem textAlign="center">{maxCapacityAtMaxFilter}</GridItem>
      <GridItem textAlign="center">{maxHeightFilter}</GridItem>
    </Grid>
  );
}
export default FilterArea;
