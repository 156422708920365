import React from 'react';
import { Card } from '@lbc-toolkit/card';
import { Flex, FlexItem } from '@lbc-toolkit/flex';
import { Icon } from '@lbc-toolkit/icon';
import { Text } from '@lbc-toolkit/text';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

const propTypes = {
  type: PropTypes.oneOf(['primary', 'light']),
  textFirstLine: PropTypes.string,
  textSecondLine: PropTypes.string,
  onClick: PropTypes.func
};

const defaultProps = {
  type: 'primary',
  textFirstLine: '',
  textSecondLine: '',
  onClick: () => {
    // do nothing...
  }
};

function DownloadButton({ type, onClick, textFirstLine, textSecondLine }) {
  return (
    <div onClick={onClick}>
      <Card type={type} height="100px" width="100px" resetBorderRadius>
        <Flex
          inline
          width="100%"
          height="100%"
          flow="column wrap"
          alignItems="center"
          justifyContent="center"
        >
          <FlexItem>
            <Text type="pSmall">{textFirstLine}</Text>
          </FlexItem>
          <FlexItem>
            <Text type="pSmall">{textSecondLine}</Text>
          </FlexItem>
          <FlexItem>
            <div style={{ height: '8px' }} />
          </FlexItem>
          <FlexItem>
            <Icon icon={faDownload} size="2x" />
          </FlexItem>
        </Flex>
      </Card>
    </div>
  );
}

DownloadButton.propTypes = exact(propTypes);

DownloadButton.defaultProps = defaultProps;

export default DownloadButton;
