import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Grid, GridItem } from '@lbc-toolkit/grid';
import FilterAreaHead from '../FilterAreaHead';
import FilterArea from '../FilterArea';
import ExtendedFilterArea from '../ExtendedFilterArea';
import CraneArea from '../CraneArea';
import TaggingArea from '../TaggingArea';
import { cranesSelected } from 'redux/selectedCranes/selectors';
import { useSelector, useDispatch } from 'react-redux';
import {
  setMaxCapacity,
  setMaxRadius,
  setMaxCapacityAtMax,
  setMaxHeight,
  setMinCapacity
} from 'redux/craneFilter/actions';
import { minCapacity } from 'redux/craneFilter/selectors';
import useQuery from '../../../hooks/useQuery';

const propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  staticContext: PropTypes.object,
  dispatch: PropTypes.func
};

const defaultProps = {
  match: {},
  history: {},
  location: {},
  staticContext: {},
  dispatch: () => {}
};

function SmartFilterPage() {
  const minimumCapacity = useSelector(state => minCapacity(state));
  const showTaggingArea = useSelector(state => cranesSelected(state));
  const dispatch = useDispatch();

  const query = useQuery();
  const maximumLoadUrlParameter = query.get('maxload');
  const minimumCapacityAtRadiusUrlParameter = query
    .get('mincapacityatradius')
    ?.split(',');
  const minimumRadiusUrlParameter = query.get('minradius');
  const minimumHookHeightUrlParameter = query.get('minhookheight');

  let takeoverUrlParameter;
  if (
    maximumLoadUrlParameter ||
    minimumCapacityAtRadiusUrlParameter ||
    minimumRadiusUrlParameter ||
    minimumHookHeightUrlParameter
  ) {
    takeoverUrlParameter = query.get('takeoverurl');
  }

  minimumHookHeightUrlParameter &&
    dispatch(setMaxHeight(Number(minimumHookHeightUrlParameter)));
  minimumCapacityAtRadiusUrlParameter?.length > 0 &&
    dispatch(
      setMaxCapacityAtMax({
        radius: Number(minimumCapacityAtRadiusUrlParameter[0] || NaN),
        capacity: Number(minimumCapacityAtRadiusUrlParameter[1] || NaN)
      })
    );
  minimumRadiusUrlParameter &&
    dispatch(setMaxRadius(Number(minimumRadiusUrlParameter)));
  maximumLoadUrlParameter &&
    dispatch(setMaxCapacity(Number(maximumLoadUrlParameter)));
  maximumLoadUrlParameter && dispatch(setMinCapacity(Number(minimumCapacity)));

  return (
    <>
      <Grid columns="repeat(12, 1fr)" rows="auto auto auto 1fr auto">
        <GridItem backgroundColor="#ffffff" />
        <GridItem area="1/2/1/12" backgroundColor="#ffffff">
          <FilterAreaHead title="Smarte Suche" />
        </GridItem>
        <GridItem backgroundColor="#ffffff" />
        <GridItem backgroundColor="#ffffff" />
        <GridItem area="2/2/2/12" backgroundColor="#ffffff">
          <FilterArea />
        </GridItem>
        <GridItem backgroundColor="#ffffff" />
        <GridItem area="3/2/3/12">
          <ExtendedFilterArea />
        </GridItem>
        <GridItem area="4/2/4/12">
          <CraneArea takeoverUrl={takeoverUrlParameter} />
        </GridItem>
      </Grid>
      {showTaggingArea && <TaggingArea />}
    </>
  );
}

SmartFilterPage.propTypes = exact(propTypes);

SmartFilterPage.defaultProps = defaultProps;

export default SmartFilterPage;
