import React from 'react';

class SilentRefresh extends React.Component {
  componentDidMount() {
    if (this.props.manager) {
      this.props.manager
        .signinSilentCallback()
        .catch(error => console.log(error));
    }
  }

  render() {
    return <div />;
  }
}

export default SilentRefresh;
