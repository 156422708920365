import * as types from './types';

export const addCrane = crane => ({
    type: types.CRANE_ADD,
    payload: crane
});

export const removeCrane = crane => ({
    type: types.CRANE_REMOVE,
    payload: crane
});

export const clearCranes = () => ({
    type: types.CRANES_CLEAR
});
