import delay from './delay';
import { cranes } from '../tools/mockData';

class CraneApi {
    static getCranes() {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve([...cranes]);
            }, delay);
        });
    }
}

export default CraneApi;
