import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

const propTypes = {
    narrow: PropTypes.bool
};

const defaultProps = {
    narrow: false
};

function Spacer({ narrow }) {
    if (narrow) {
        return <div style={{ width: '8px' }} />;
    }

    return <div style={{ width: '16px' }} />;
}

Spacer.propTypes = exact(propTypes);

Spacer.defaultProps = defaultProps;

export default Spacer;
