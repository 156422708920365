import React from 'react';
import { Flex, FlexItem } from '@lbc-toolkit/flex';
import { Icon } from '@lbc-toolkit/icon';
import { Text } from '@lbc-toolkit/text';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';

function AccessDenied() {
  return (
    <Flex
      alignContent="middle"
      justifyContent="center"
      width="100%"
      height="100vh"
    >
      <FlexItem alignSelf="center">
        <Icon src={faExclamationTriangle} color="#cf0000" size="5x" />
      </FlexItem>
      <FlexItem alignSelf="center">
        <Text type="h1" color="#cf0000">
          You have no access to this app!
        </Text>
      </FlexItem>
    </Flex>
  );
}

export default AccessDenied;
