import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, GridItem } from '@lbc-toolkit/grid';
import { Link } from 'react-router-dom';
import { Text } from '@lbc-toolkit/text';
import ConfigCard from '../ConfigCard';
import {
  faSearch,
  faCog,
  faLayerGroup
} from '@fortawesome/pro-solid-svg-icons';
import { navigateToSmart } from '../../../redux/menuItem/actions';
import styles from './ConfigCards.module.css';

const propTypes = {
  actions: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};

function ConfigCards({ actions, title }) {
  return (
    <Grid
      padding="24px 0"
      columns="repeat(3, auto)"
      gap="12px"
      justifyContent="space-between"
    >
      <GridItem area="1/1/1/4">
        <Text type="h4">{title}</Text>
      </GridItem>
      <GridItem>
        <Link
          to="/smart"
          className={styles.noTextDecoration}
          onClick={actions.navigateToSmart}
        >
          <ConfigCard
            title="Smart"
            description="Konfiguration anhand weniger Einstellungen"
            icon={faSearch}
            main
          />
        </Link>
      </GridItem>
      <GridItem>
        <ConfigCard
          title="Standard"
          description="Standard Konfiguration mit vielen Einstellungsmöglichkeiten"
          icon={faLayerGroup}
          disabled
        />
      </GridItem>
      <GridItem>
        <ConfigCard
          title="Construction"
          description="Konfiguration anhand Parameter direkt aus der Bauplanung"
          icon={faCog}
          disabled
        />
      </GridItem>
    </Grid>
  );
}

ConfigCards.propTypes = exact(propTypes);

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        navigateToSmart
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigCards);
