import React from 'react';
import { Redirect } from 'react-router-dom';
import { Grid, GridItem } from '@lbc-toolkit/grid';
import { Text } from '@lbc-toolkit/text';

class Callback extends React.Component {
  state = {
    redirect: false
  };

  componentDidMount() {
    const manager = this.props.manager;
    const updateUser = this.props.updateUser;

    manager
      .signinRedirectCallback()
      .then(user => {
        updateUser(user);
        this.setState({ redirect: true });
      })
      .catch(error => console.log('error:', error));
  }

  render() {
    return (
      <Grid>
        <GridItem padding="40% 0px" textAlign="center">
          <Text type="h1">Logging In...</Text>
          {this.state.redirect && <Redirect to="/" />}
        </GridItem>
      </Grid>
    );
  }
}

export default Callback;
