import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Flex, FlexItem } from '@lbc-toolkit/flex';
import { Card } from '@lbc-toolkit/card';
import { Icon } from '@lbc-toolkit/icon';
import { Tag } from '@lbc-toolkit/tag';
import { Text } from '@lbc-toolkit/text';
import styles from './ConfigCard.module.css';

const propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  main: PropTypes.bool,
  disabled: PropTypes.bool
};

const defaultProps = {
  main: false,
  disabled: false
};

function ConfigCard({ title, description, icon, main, disabled }) {
  return (
    <Card
      type={main ? 'primary' : 'light'}
      width="375px"
      height="260px"
      className={disabled ? styles.disabled : ''}
      resetBorderRadius
    >
      <Flex
        inline
        width="100%"
        height="100%"
        flow="column nowrap"
        alignItems="center"
        justifyContent="center"
      >
        {disabled && (
          <FlexItem className={styles.textContainer}>
            <Tag label="Später verfügbar" type="greyStandard" />
          </FlexItem>
        )}
        <FlexItem>
          <Icon
            className={disabled ? styles.iconDisabled : styles.icon}
            src={icon}
            size="6x"
          />
        </FlexItem>
        <FlexItem className={styles.textContainer}>
          <Text type="h2">{title}</Text>
          <Text type="p">{description}</Text>
        </FlexItem>
      </Flex>
    </Card>
  );
}

ConfigCard.propTypes = exact(propTypes);

ConfigCard.defaultProps = defaultProps;

export default ConfigCard;
