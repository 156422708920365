import CraneModelApi from '../api/CraneModelApi';

const getCraneModel = async crane => {
  try {
    return await CraneModelApi.getCraneModel(crane);
  } catch (error) {
    return null;
  }
};

const getCraneModelFileName = async crane => {
  try {
    return await CraneModelApi.getCraneModelFileName(crane);
  } catch (error) {
    return null;
  }
};

export { getCraneModel, getCraneModelFileName };
