import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Icon } from '@lbc-toolkit/icon';
import { Text } from '@lbc-toolkit/text';
import styles from './InfoTooltip.module.css';

const propTypes = {
    icon: PropTypes.object.isRequired,
    text: PropTypes.string
};

const defaultProps = {
    text: ''
};

function InfoTooltip({ icon, text }) {
    const [tooltipWidth, setTooltipWidth] = useState(0);
    const [tooltipX, setTooltipX] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const measuredRef = useCallback(node => {
        if (node !== null) {
            setTooltipWidth(node.getBoundingClientRect().width);
            setTooltipX(node.getBoundingClientRect().x);
        }
    }, []);

    if (!text) return <Icon src={icon} color="#c7c6c5" />;

    const width =
        tooltipX + tooltipWidth > windowWidth ? 'min-content' : 'max-content';

    return (
        <div className={styles.tooltip}>
            <Icon src={icon} color="#c7c6c5" />
            <div
                ref={measuredRef}
                className={styles.tooltiptext}
                style={{ width: width }}
            >
                <Text type="pSmall">{text}</Text>
            </div>
        </div>
    );
}

InfoTooltip.propTypes = exact(propTypes);

InfoTooltip.defaultProps = defaultProps;

export default InfoTooltip;
