import { UserManager, WebStorageStateStore } from 'oidc-client';

export const Manager = new UserManager({
  authority: process.env.REACT_APP_OIDC_AUTHORITY,
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
  redirect_uri: window.location.origin + '/signin',
  silent_redirect_uri: window.location.origin + '/silent-refresh',
  login: process.env.REACT_APP_OIDC_AUTHORITY + '/login',
  grantType: 'implicit',
  response_type: 'id_token token',
  webAuthResponseType: 'id_token token',
  scope: 'openid profile email role',
  loadUserInfo: false,
  automaticSilentRenew: true,
  userStore: new WebStorageStateStore({
    store: window.sessionStorage,
  }),
});
