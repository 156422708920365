/**
 * Return request model for a crane.
 *
 * @param {*} crane
 *
 * @returns {
 *   type: string,
 *   radius: Number,
 *   height: Number,
 *   tower: string,
 *   base: string,
 *   format: string
 * }
 */
export default function mapCraneModelRequest(crane, format) {
  return {
    type: crane.name,
    radius: crane.radius,
    height: crane.height,
    tower: crane.tower,
    base: crane.base,
    format: format.toLowerCase()
  };
}
