import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, GridItem } from '@lbc-toolkit/grid';
import { Text } from '@lbc-toolkit/text';
import { Link } from 'react-router-dom';
import { navigateToMyCranes } from '../../../redux/menuItem/actions';
import styles from './CraneCardsTitle.module.css';

const propTypes = {
  actions: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  showAll: PropTypes.bool
};

const defaultProps = {
  showAll: false
};

function CraneCardsTitle({ actions, title, showAll }) {
  return (
    <Grid columns="auto 1fr auto" justifyContent="space-between">
      <GridItem>
        <Text type="h4">{title}</Text>
      </GridItem>
      <GridItem />
      <GridItem>
        {showAll && (
          <Link
            to="/mycranes"
            className={styles.link}
            onClick={actions.navigateToMyCranes}
          >
            <Text type="pSmall">Alle anzeigen</Text>
          </Link>
        )}
      </GridItem>
    </Grid>
  );
}

CraneCardsTitle.propTypes = exact(propTypes);

CraneCardsTitle.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        navigateToMyCranes
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CraneCardsTitle);
