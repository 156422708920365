import * as types from './types';
import CranesApi from '../../api/mockCraneApi';

const loadCranesLoading = () => ({
    type: types.CRANES_LOADING
});

const loadCranesSuccess = cranes => ({
    type: types.CRANES_SUCCESS,
    cranes: cranes
});

const loadCranesFailure = () => ({
    type: types.CRANES_FAILURE
});

export const loadCranes = () => {
    return dispatch => {
        dispatch(loadCranesLoading());

        return CranesApi.getCranes()
            .then(cranes => {
                dispatch(loadCranesSuccess(cranes));
            })
            .catch(error => {
                // TODO: Remove Console log
                console.log(error);
                dispatch(loadCranesFailure());
            });
    };
};
