import { postDownloadRequestApiKey, postModelFileNameRequestApiKey } from './request';

const url = process.env.REACT_APP_MODEL_API_ADDRESS;

class CraneModelApi {
  static async getCraneModel(craneModelRequest) {
    return postDownloadRequestApiKey(
      process.env.REACT_APP_MODEL_API_KEY,
      `${url}/CraneModels`,
      JSON.stringify(craneModelRequest)
    );
  }

  static async getCraneModelFileName(craneModelRequest) {
    return postModelFileNameRequestApiKey(
      process.env.REACT_APP_MODEL_API_KEY,
      `${url}/CraneModels/filename`,
      JSON.stringify(craneModelRequest)
    );
  }
}

export default CraneModelApi;
