import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Flex, FlexItem } from '@lbc-toolkit/flex';
import { Grid, GridItem } from '@lbc-toolkit/grid';
import { Text } from '@lbc-toolkit/text';
import InfoTooltip from './InfoTooltip';
import Spacer from '../common/Spacer';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

const propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  tooltip: PropTypes.string,
  range: PropTypes.bool,
  unit: PropTypes.oneOf(['m', 't']).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func
};

const defaultProps = {
  title: 'Placeholder',
  image: '',
  tooltip: '',
  range: false,
  value: 0,
  minValue: 0,
  maxValue: 100,
  step: 1,
  onChange: () => {}
};

function Filter({
  title,
  image,
  tooltip,
  unit,
  value,
  range,
  minValue,
  maxValue,
  step,
  onChange
}) {
  const [internalValue, setValue] = useState(value);
  const [isValueOutOfRange, setIsValueOutOfRange] = useState(false);

  const ValueOutOfRangeToolTip = () => (
    <InfoTooltip
      icon={faInfoCircle}
      text={`Der/Die übergebene/n Wert/e sind unzulässig. Minimum: ${minValue}, Maximum: ${maxValue}`}
    />
  );

  useEffect(() => {
    const rangeValidation =
      isNaN(internalValue[0]) ||
      isNaN(internalValue[1]) ||
      internalValue[0] < minValue ||
      internalValue[0] > maxValue ||
      internalValue[1] < minValue ||
      internalValue[1] > maxValue ||
      internalValue[1] < internalValue[0];

    const singleNumberValidation =
      isNaN(internalValue) ||
      internalValue < minValue ||
      internalValue > maxValue;

    const validationError = range ? rangeValidation : singleNumberValidation;

    validationError ? setIsValueOutOfRange(true) : setIsValueOutOfRange(false);
  }, [internalValue, minValue, maxValue, range]);

  return (
    <Grid gap="12px" justifyContent="stretch" justifyItems="center">
      <GridItem>
        <Flex alignItems="center">
          <Text type="pSmall">{title}</Text>
          <Spacer narrow />
          <InfoTooltip icon={faInfoCircle} text={tooltip} />
        </Flex>
      </GridItem>
      <GridItem>
        <img alt={title} src={image} />
      </GridItem>
      <GridItem>
        {range && (
          <Flex flow="row">
            <FlexItem>
              <Text type="h5" color={isValueOutOfRange ? 'red' : 'black'}>
                {isNaN(internalValue[0]) ? '?' : internalValue[0]} - {isNaN(internalValue[1]) ? '?' : internalValue[1]} {unit}
              </Text>
            </FlexItem>
            {isValueOutOfRange && (
              <FlexItem margin="0 0 0 8px">
                <ValueOutOfRangeToolTip />
              </FlexItem>
            )}
          </Flex>
        )}
        {!range && (
          <Flex flow="row">
            <FlexItem>
              <Text type="h5" color={isValueOutOfRange ? 'red' : 'black'}>
                {isNaN(internalValue) ? '?' : internalValue} {unit}
              </Text>
            </FlexItem>
            {isValueOutOfRange && (
              <FlexItem margin="0 0 0 8px">
                <ValueOutOfRangeToolTip />
              </FlexItem>
            )}
          </Flex>
        )}
      </GridItem>
      <GridItem justifySelf="stretch">
        {range && (
          <Range
            value={internalValue}
            min={minValue}
            max={maxValue}
            step={step}
            onChange={range => setValue(range)}
            onAfterChange={onChange}
            handleStyle={[
              {
                backgroundColor: 'black',
                border: 'none'
              },
              {
                backgroundColor: 'black',
                border: 'none'
              }
            ]}
            trackStyle={[{ backgroundColor: '#fdc400' }]}
          />
        )}
        {!range && (
          <Slider
            value={internalValue}
            min={minValue}
            max={maxValue}
            step={step}
            onChange={value => setValue(value)}
            onAfterChange={onChange}
            handleStyle={{
              backgroundColor: 'black',
              border: 'none'
            }}
            trackStyle={{ backgroundColor: '#fdc400' }}
          />
        )}
      </GridItem>
    </Grid>
  );
}

Filter.propTypes = exact(propTypes);

Filter.defaultProps = defaultProps;

export default Filter;
