import * as types from './types';

const initialState = {
    cranes: [],
    status: types.CRANES_LOADING
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.CRANES_LOADING:
            return { ...state, status: types.CRANES_LOADING };
        case types.CRANES_SUCCESS:
            return { cranes: action.cranes, status: types.CRANES_SUCCESS };
        case types.CRANES_FAILURE:
            return { ...state, status: types.CRANES_FAILURE };
        default:
            return state;
    }
};
