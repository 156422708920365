import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Grid, GridItem } from '@lbc-toolkit/grid';
import { Text } from '@lbc-toolkit/text';

const propTypes = {
  title: PropTypes.string.isRequired
};

function HomePageTitle({ title }) {
  return (
    <Grid padding="24px 0" columns="1fr">
      <GridItem>
        <Text type="h1">{title}</Text>
      </GridItem>
    </Grid>
  );
}

HomePageTitle.propTypes = exact(propTypes);

export default HomePageTitle;
