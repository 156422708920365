import React from 'react';
import { Grid, GridItem } from '@lbc-toolkit/grid';
import ConfigCards from '../../common/ConfigCards';
import NewConfigPageTitle from '../NewConfigPageTitle';

class NewConfigPage extends React.Component {
  render() {
    return (
      <Grid columns="repeat(12, 1fr)">
        <GridItem area="1/2/1/12">
          <NewConfigPageTitle title="Neue Krankonfiguration" />
        </GridItem>
        <GridItem area="2/2/2/12">
          <ConfigCards title="Wählen Sie einen Assistenten aus, um in die Konfiguration einzusteigen." />
        </GridItem>
      </Grid>
    );
  }
}

export default NewConfigPage;
