import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, Provider } from 'react-redux';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { Manager } from '../tools/UserManager';
import Callback from '../components/auth/Callback';
import SilentRefresh from '../components/auth/SilentRefresh';
import { loginUser } from '../redux/user/actions';
import App from './App';
import { NAVIGATION } from 'consts/navigation';

function Root({ actions, store }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (!user) {
      Manager.getUser().then(acquiredUser => {
        setUser(acquiredUser);
      });
    }
  }, [actions, user]);

  useEffect(() => {
    if (user) {
      actions.loginUser(user);
    }
  }, [actions, user]);

  return (
    <Provider store={store}>
      <Router>
        <Route
          exact
          path={[
            NAVIGATION.main.routes.ROOT,
            NAVIGATION.newConfiguration.routes.START,
            NAVIGATION.newConfiguration.routes.SMART,
            NAVIGATION.myCranes.routes.MYCRANES,
            NAVIGATION.myCranes.routes.CRANEDETAILS
          ]}
          render={props => <App {...props} manager={Manager} user={user} />}
        />
        <Route
          exact
          path="/signin"
          render={props => (
            <Callback {...props} manager={Manager} updateUser={setUser} />
          )}
        />
        <Route
          exact
          path="/silent-refresh"
          render={props => <SilentRefresh {...props} manager={Manager} />}
        />
      </Router>
    </Provider>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ loginUser }, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(Root);
