import produce from 'immer';
import * as types from './types';

const initialState = {
    cranes: []
};

export default produce((draft, action) => {
    switch (action.type) {
        case types.CRANE_ADD:
            draft.cranes.push(action.payload);
            return;
        case types.CRANE_REMOVE:
            const index = draft.cranes.findIndex(
                crane => crane.id === action.payload.id
            );
            draft.cranes.splice(index, 1);
            return;
        case types.CRANES_CLEAR:
            draft.cranes = [];
            return;
        default:
            return;
    }
}, initialState);
