import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import ResultArea from './ResultArea';
import * as craneSelectors from '../../redux/cranes/selectors';
import {
  addCrane as selectCrane,
  removeCrane as deselectCrane
} from '../../redux/selectedCranes/actions';
import { useSelector, useDispatch } from 'react-redux';
import { navigateToDetails } from 'redux/menuItem/actions';
import { useHistory } from 'react-router-dom';

const propTypes = {
  takeoverUrl: PropTypes.string
};

const defaultProps = {
  takeoverUrl: undefined
};

function CraneArea({ takeoverUrl }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSelectCrane = (crane, selected) => {
    if (takeoverUrl) {
      const location = {
        pathname: '/cranedetails',
        state: { crane: crane, takeoverUrl: takeoverUrl }
      };
      history.push(location);
      dispatch(navigateToDetails());
    } else {
      if (selected) {
        dispatch(selectCrane(crane));
      } else {
        dispatch(deselectCrane(crane));
      }
    }
  };

  const cranes = useSelector(state => craneSelectors.filteredCranes(state));
  const loading = useSelector(state => craneSelectors.isLoading(state));

  return (
    <ResultArea
      cranes={cranes}
      loading={loading}
      onSelect={handleSelectCrane}
      selectable={!takeoverUrl}
    />
  );
}

CraneArea.propTypes = exact(propTypes);
CraneArea.defaultProps = defaultProps;

export default CraneArea;
