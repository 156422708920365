import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, GridItem } from '@lbc-toolkit/grid';
import Logo from '../Logo';
import HomePageTitle from '../HomePageTitle';
import ConfigCards from '../../common/ConfigCards';
import CraneCards from '../../common/CraneCards';
import * as myCraneSelectors from '../../../redux/myCranes/selectors';
import { navigateToDetails } from '../../../redux/menuItem/actions';

const propTypes = {
  actions: PropTypes.object.isRequired,
  cranes: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  staticContext: PropTypes.object
};

const defaultProps = {
  match: {},
  history: {},
  location: {},
  staticContext: {}
};

export class HomePage extends React.Component {
  constructor(props) {
    super(props);

    this.handleRedirect = this.handleRedirect.bind(this);
  }

  handleRedirect(crane) {
    const { actions, history } = this.props;

    const location = {
      pathname: '/cranedetails',
      state: { crane: crane }
    };
    history.push(location);
    actions.navigateToDetails();
  }

  render() {
    const { cranes } = this.props;

    return (
      <Grid columns="repeat(12, 1fr)">
        <GridItem area="1/4/1/13">
          <Logo />
        </GridItem>
        <GridItem area="2/4/2/12">
          <HomePageTitle title="Crane Configurator" />
        </GridItem>
        <GridItem area="3/4/3/12">
          <ConfigCards title="Einstieg wählen" />
        </GridItem>
        <GridItem area="4/4/4/12">
          <CraneCards
            title="Zuletzt konfiguriert"
            cranes={cranes}
            onSelect={this.handleRedirect}
          />
        </GridItem>
      </Grid>
    );
  }
}

HomePage.propTypes = exact(propTypes);

HomePage.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    cranes: myCraneSelectors.cranes(state),
    loading: myCraneSelectors.isLoading(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        navigateToDetails
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
