import * as types from './types';

const initialState = {
  minCapacity: 0,
  maxCapacity: 11,
  maxRadius: 50,
  maxCapacityAtMax: {
    radius: 50,
    capacity: 6.2
  },
  maxHeight: 50,
  base: [],
  craneType: [],
  tower: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_MIN_CAPACITY:
      return { ...state, minCapacity: action.minCapacity };
    case types.SET_MAX_CAPACITY:
      return { ...state, maxCapacity: action.maxCapacity };
    case types.SET_MAX_RADIUS:
      return { ...state, maxRadius: action.maxRadius };
    case types.SET_MAX_CAPACITY_AT_MAX:
      return { ...state, maxCapacityAtMax: action.maxCapacityAtMax };
    case types.SET_MAX_HEIGHT:
      return { ...state, maxHeight: action.maxHeight };
    case types.ADD_BASE:
      return { ...state, base: [...state.base, action.base] };
    case types.REMOVE_BASE:
      const index = state.base.indexOf(action.base);
      if (index < 0) return state;

      return { ...state, base: state.base.splice(index, 1) };
    case types.SET_BASE:
      return { ...state, base: action.base };
    case types.SET_CRANE_TYPE:
      return { ...state, craneType: action.craneType };
    case types.SET_TOWER:
      return { ...state, tower: action.tower };
    default:
      return state;
  }
};
