const bases = [
  {
    name: '21 HC 290 FA',
    type: '280 EC-H 12 Litronic',
    heights: [
      45.61,
      49.75,
      58.01,
      62.15,
      66.27,
      70.41,
      74.55,
      82.81,
      86.95,
      91.07,
      95.21
    ]
  },
  {
    name: '21 HC 290 CB-0450',
    type: '280 EC-H 12 Litronic',
    heights: [
      33.01,
      37.15,
      45.41,
      49.55,
      53.67,
      57.81,
      61.95,
      70.21,
      74.35,
      78.47,
      82.61
    ]
  },
  {
    name: '21 HC 290 UC-0600',
    type: '280 EC-H 12 Litronic',
    heights: [
      35.81,
      39.95,
      48.21,
      52.35,
      56.47,
      60.61,
      64.75,
      73.01,
      77.15,
      81.27,
      85.41
    ]
  },
  {
    name: '21 HC 290 UC-0800',
    type: '280 EC-H 12 Litronic',
    heights: [
      38.71,
      42.85,
      51.11,
      55.25,
      59.37,
      63.51,
      67.65,
      75.91,
      80.05,
      84.17,
      88.31
    ]
  },
  {
    name: '21 HC 290 FA',
    type: '370 EC-B 12 Fibre',
    heights: [
      42.4,
      46.54,
      54.8,
      58.94,
      63.06,
      67.2,
      71.34,
      79.6,
      83.74,
      87.86,
      92
    ]
  },
  {
    name: '21 HC 290 CB-0450',
    type: '370 EC-B 12 Fibre',
    heights: [
      29.8,
      33.94,
      42.2,
      46.34,
      50.46,
      54.6,
      58.74,
      67.0,
      71.14,
      75.26,
      79.4
    ]
  },
  {
    name: '21 HC 290 UC-0600',
    type: '370 EC-B 12 Fibre',
    heights: [
      32.6,
      36.74,
      45.0,
      49.14,
      53.26,
      57.4,
      61.54,
      69.8,
      73.94,
      78.06,
      82.2
    ]
  },
  {
    name: '21 HC 290 UC-0800',
    type: '370 EC-B 12 Fibre',
    heights: [
      35.5,
      39.64,
      47.9,
      52.04,
      56.16,
      60.3,
      64.44,
      72.7,
      76.84,
      80.96,
      85.1
    ]
  }
];

const curves = [
  {
    name: '280 EC-H 12 Litronic',
    curves: [
      {
        radius: 75.0,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 11499,
          23: 10939,
          24: 10426,
          25: 9954,
          26: 9519,
          27: 9118,
          28: 8745,
          29: 8398,
          30: 8075,
          31: 7773,
          32: 7490,
          33: 7224,
          34: 6974,
          35: 6739,
          36: 6517,
          37: 6307,
          38: 6108,
          39: 5920,
          40: 5741,
          41: 5570,
          42: 5408,
          43: 5254,
          44: 5107,
          45: 4966,
          46: 4832,
          47: 4703,
          48: 4580,
          49: 4461,
          50: 4348,
          51: 4239,
          52: 4134,
          53: 4033,
          54: 3936,
          55: 3843,
          56: 3753,
          57: 3666,
          58: 3582,
          59: 3501,
          60: 3423,
          61: 3347,
          62: 3274,
          63: 3203,
          64: 3134,
          65: 3067,
          66: 3003,
          67: 2940,
          68: 2880,
          69: 2821,
          70: 2763,
          71: 2708,
          72: 2654,
          73: 2601,
          74: 2550,
          75: 2500
        }
      },
      {
        radius: 70.0,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 11668,
          24: 11124,
          25: 10624,
          26: 10163,
          27: 9737,
          28: 9342,
          29: 8974,
          30: 8632,
          31: 8311,
          32: 8011,
          33: 7730,
          34: 7465,
          35: 7215,
          36: 6980,
          37: 6757,
          38: 6546,
          39: 6346,
          40: 6157,
          41: 5976,
          42: 5805,
          43: 5641,
          44: 5485,
          45: 5336,
          46: 5193,
          47: 5056,
          48: 4926,
          49: 4800,
          50: 4680,
          51: 4564,
          52: 4453,
          53: 4347,
          54: 4244,
          55: 4145,
          56: 4049,
          57: 3957,
          58: 3868,
          59: 3782,
          60: 3699,
          61: 3619,
          62: 3541,
          63: 3466,
          64: 3393,
          65: 3322,
          66: 3254,
          67: 3188,
          68: 3123,
          69: 3061,
          70: 3000
        }
      },
      {
        radius: 65.0,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 12000,
          24: 11610,
          25: 11091,
          26: 10612,
          27: 10169,
          28: 9758,
          29: 9376,
          30: 9019,
          31: 8686,
          32: 8374,
          33: 8082,
          34: 7806,
          35: 7547,
          36: 7302,
          37: 7071,
          38: 6851,
          39: 6644,
          40: 6446,
          41: 6259,
          42: 6080,
          43: 5910,
          44: 5748,
          45: 5593,
          46: 5444,
          47: 5303,
          48: 5167,
          49: 5036,
          50: 4911,
          51: 4791,
          52: 4676,
          53: 4565,
          54: 4458,
          55: 4355,
          56: 4255,
          57: 4160,
          58: 4067,
          59: 3978,
          60: 3891,
          61: 3808,
          62: 3727,
          63: 3649,
          64: 3573,
          65: 3500
        }
      },
      {
        radius: 60.0,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 12000,
          24: 12000,
          25: 11597,
          26: 11098,
          27: 10636,
          28: 10209,
          29: 9811,
          30: 9440,
          31: 9093,
          32: 8768,
          33: 8463,
          34: 8176,
          35: 7906,
          36: 7651,
          37: 7410,
          38: 7182,
          39: 6966,
          40: 6760,
          41: 6565,
          42: 6379,
          43: 6202,
          44: 6033,
          45: 5871,
          46: 5717,
          47: 5569,
          48: 5428,
          49: 5292,
          50: 5162,
          51: 5037,
          52: 4917,
          53: 4801,
          54: 4690,
          55: 4582,
          56: 4479,
          57: 4379,
          58: 4283,
          59: 4190,
          60: 4100
        }
      },
      {
        radius: 55.0,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 12000,
          24: 12000,
          25: 12000,
          26: 11562,
          27: 11083,
          28: 10639,
          29: 10226,
          30: 9841,
          31: 9481,
          32: 9144,
          33: 8828,
          34: 8530,
          35: 8250,
          36: 7985,
          37: 7735,
          38: 7498,
          39: 7274,
          40: 7061,
          41: 6858,
          42: 6665,
          43: 6481,
          44: 6306,
          45: 6138,
          46: 5978,
          47: 5824,
          48: 5678,
          49: 5537,
          50: 5402,
          51: 5272,
          52: 5147,
          53: 5027,
          54: 4911,
          55: 4800
        }
      },
      {
        radius: 50.0,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 12000,
          24: 12000,
          25: 12000,
          26: 11947,
          27: 11454,
          28: 10996,
          29: 10571,
          30: 10174,
          31: 9803,
          32: 9456,
          33: 9130,
          34: 8823,
          35: 8534,
          36: 8262,
          37: 8004,
          38: 7760,
          39: 7529,
          40: 7309,
          41: 7100,
          42: 6902,
          43: 6712,
          44: 6531,
          45: 6359,
          46: 6194,
          47: 6036,
          48: 5884,
          49: 5739,
          50: 5600
        }
      },
      {
        radius: 45.0,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 12000,
          24: 12000,
          25: 12000,
          26: 12000,
          27: 11691,
          28: 11225,
          29: 10791,
          30: 10387,
          31: 10009,
          32: 9655,
          33: 9323,
          34: 9011,
          35: 8717,
          36: 8439,
          37: 8176,
          38: 7928,
          39: 7692,
          40: 7468,
          41: 7256,
          42: 7053,
          43: 6860,
          44: 6676,
          45: 6500
        }
      },
      {
        radius: 40.0,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 12000,
          24: 12000,
          25: 12000,
          26: 12000,
          27: 11887,
          28: 11414,
          29: 10974,
          30: 10563,
          31: 10180,
          32: 9821,
          33: 9483,
          34: 9166,
          35: 8867,
          36: 8585,
          37: 8319,
          38: 8067,
          39: 7827,
          40: 7600
        }
      },
      {
        radius: 35.0,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 12000,
          24: 12000,
          25: 12000,
          26: 12000,
          27: 11800,
          28: 11329,
          29: 10892,
          30: 10485,
          31: 10104,
          32: 9747,
          33: 9412,
          34: 9097,
          35: 8800
        }
      },
      {
        radius: 30.0,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 12000,
          24: 12000,
          25: 12000,
          26: 12000,
          27: 11928,
          28: 11453,
          29: 11012,
          30: 10600
        }
      },
      {
        radius: 25.0,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 12000,
          24: 12000,
          25: 12000
        }
      }
    ]
  },
  {
    name: '370 EC-B 12 Fibre',
    curves: [
      {
        radius: 78.0,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 11476,
          21: 10880,
          22: 10339,
          23: 9847,
          24: 9397,
          25: 8985,
          26: 8605,
          27: 8254,
          28: 7929,
          29: 7626,
          30: 7345,
          31: 7082,
          32: 6836,
          33: 6605,
          34: 6388,
          35: 6183,
          36: 5991,
          37: 5808,
          38: 5636,
          39: 5473,
          40: 5318,
          41: 5170,
          42: 5030,
          43: 4897,
          44: 4769,
          45: 4648,
          46: 4531,
          47: 4420,
          48: 4314,
          49: 4211,
          50: 4114,
          51: 4019,
          52: 3929,
          53: 3842,
          54: 3758,
          55: 3678,
          56: 3600,
          57: 3525,
          58: 3453,
          59: 3383,
          60: 3316,
          61: 3250,
          62: 3187,
          63: 3126,
          64: 3067,
          65: 3010,
          66: 2954,
          67: 2900,
          68: 2848,
          69: 2797,
          70: 2748,
          71: 2700,
          72: 2654,
          73: 2609,
          74: 2565,
          75: 2522,
          76: 2480,
          77: 2440,
          78: 2400
        }
      },
      {
        radius: 75.0,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 11434,
          24: 10917,
          25: 10442,
          26: 10005,
          27: 9601,
          28: 9226,
          29: 8878,
          30: 8554,
          31: 8252,
          32: 7968,
          33: 7702,
          34: 7452,
          35: 7217,
          36: 6995,
          37: 6786,
          38: 6587,
          39: 6399,
          40: 6220,
          41: 6051,
          42: 5889,
          43: 5736,
          44: 5589,
          45: 5449,
          46: 5315,
          47: 5187,
          48: 5064,
          49: 4946,
          50: 4834,
          51: 4725,
          52: 4621,
          53: 4521,
          54: 4425,
          55: 4332,
          56: 4242,
          57: 4156,
          58: 4073,
          59: 3992,
          60: 3915,
          61: 3839,
          62: 3767,
          63: 3696,
          64: 3628,
          65: 3562,
          66: 3498,
          67: 3436,
          68: 3376,
          69: 3318,
          70: 3261,
          71: 3206,
          72: 3152,
          73: 3100,
          74: 3049,
          75: 3000
        }
      },
      {
        radius: 72.5,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 11661,
          24: 11134,
          25: 10651,
          26: 10206,
          27: 9795,
          28: 9414,
          29: 9060,
          30: 8730,
          31: 8422,
          32: 8133,
          33: 7863,
          34: 7608,
          35: 7369,
          36: 7143,
          37: 6929,
          38: 6727,
          39: 6535,
          40: 6353,
          41: 6181,
          42: 6016,
          43: 5859,
          44: 5710,
          45: 5567,
          46: 5431,
          47: 5300,
          48: 5175,
          49: 5055,
          50: 4940,
          51: 4830,
          52: 4724,
          53: 4622,
          54: 4524,
          55: 4429,
          56: 4338,
          57: 4250,
          58: 4165,
          59: 4083,
          60: 4004,
          61: 3927,
          62: 3853,
          63: 3782,
          64: 3712,
          65: 3645,
          66: 3580,
          67: 3516,
          68: 3455,
          69: 3395,
          70: 3338,
          71: 3281,
          72: 3227
        }
      },
      {
        radius: 70.0,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 12000,
          24: 11621,
          25: 11118,
          26: 10654,
          27: 10226,
          28: 9829,
          29: 9460,
          30: 9116,
          31: 8795,
          32: 8495,
          33: 8213,
          34: 7948,
          35: 7698,
          36: 7463,
          37: 7240,
          38: 7030,
          39: 6830,
          40: 6641,
          41: 6461,
          42: 6289,
          43: 6126,
          44: 5970,
          45: 5822,
          46: 5680,
          47: 5544,
          48: 5414,
          49: 5289,
          50: 5169,
          51: 5054,
          52: 4944,
          53: 4837,
          54: 4735,
          55: 4637,
          56: 4542,
          57: 4450,
          58: 4362,
          59: 4276,
          60: 4194,
          61: 4114,
          62: 4037,
          63: 3962,
          64: 3890,
          65: 3820,
          66: 3752,
          67: 3686,
          68: 3622,
          69: 3560,
          70: 3500
        }
      },
      {
        radius: 67.5,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 12000,
          24: 12000,
          25: 11532,
          26: 11051,
          27: 10607,
          28: 10196,
          29: 9813,
          30: 9457,
          31: 9124,
          32: 8813,
          33: 8521,
          34: 8246,
          35: 7987,
          36: 7743,
          37: 7513,
          38: 7295,
          39: 7088,
          40: 6892,
          41: 6706,
          42: 6528,
          43: 6359,
          44: 6198,
          45: 6044,
          46: 5897,
          47: 5756,
          48: 5621,
          49: 5492,
          50: 5368,
          51: 5249,
          52: 5135,
          53: 5025,
          54: 4919,
          55: 4817,
          56: 4719,
          57: 4624,
          58: 4532,
          59: 4444,
          60: 4359,
          61: 4276,
          62: 4196,
          63: 4119,
          64: 4044,
          65: 3972,
          66: 3901,
          67: 3833
        }
      },
      {
        radius: 65.0,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 12000,
          24: 12000,
          25: 11845,
          26: 11353,
          27: 10899,
          28: 10478,
          29: 10087,
          30: 9722,
          31: 9382,
          32: 9063,
          33: 8764,
          34: 8482,
          35: 8218,
          36: 7968,
          37: 7732,
          38: 7508,
          39: 7296,
          40: 7095,
          41: 6904,
          42: 6722,
          43: 6549,
          44: 6384,
          45: 6226,
          46: 6075,
          47: 5931,
          48: 5793,
          49: 5660,
          50: 5533,
          51: 5411,
          52: 5294,
          53: 5181,
          54: 5072,
          55: 4967,
          56: 4867,
          57: 4769,
          58: 4675,
          59: 4585,
          60: 4497,
          61: 4412,
          62: 4331,
          63: 4251,
          64: 4174,
          65: 4100
        }
      },
      {
        radius: 62.5,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 12000,
          24: 12000,
          25: 11896,
          26: 11401,
          27: 10943,
          28: 10519,
          29: 10125,
          30: 9758,
          31: 9416,
          32: 9095,
          33: 8794,
          34: 8511,
          35: 8245,
          36: 7993,
          37: 7756,
          38: 7531,
          39: 7319,
          40: 7116,
          41: 6924,
          42: 6742,
          43: 6568,
          44: 6402,
          45: 6243,
          46: 6092,
          47: 5947,
          48: 5808,
          49: 5675,
          50: 5547,
          51: 5424,
          52: 5307,
          53: 5193,
          54: 5084,
          55: 4979,
          56: 4878,
          57: 4780,
          58: 4686,
          59: 4595,
          60: 4507,
          61: 4422,
          62: 4340
        }
      },
      {
        radius: 60.0,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 12000,
          24: 12000,
          25: 12000,
          26: 11850,
          27: 11376,
          28: 10936,
          29: 10528,
          30: 10147,
          31: 9791,
          32: 9459,
          33: 9147,
          34: 8853,
          35: 8577,
          36: 8316,
          37: 8070,
          38: 7837,
          39: 7616,
          40: 7407,
          41: 7208,
          42: 7018,
          43: 6837,
          44: 6665,
          45: 6501,
          46: 6344,
          47: 6193,
          48: 6049,
          49: 5911,
          50: 5779,
          51: 5652,
          52: 5529,
          53: 5412,
          54: 5299,
          55: 5190,
          56: 5085,
          57: 4983,
          58: 4886,
          59: 4791,
          60: 4700
        }
      },
      {
        radius: 57.5,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 12000,
          24: 12000,
          25: 12000,
          26: 12000,
          27: 11520,
          28: 11075,
          29: 10661,
          30: 10276,
          31: 9916,
          32: 9579,
          33: 9263,
          34: 8966,
          35: 8687,
          36: 8423,
          37: 8174,
          38: 7938,
          39: 7715,
          40: 7502,
          41: 7301,
          42: 7109,
          43: 6926,
          44: 6752,
          45: 6586,
          46: 6426,
          47: 6274,
          48: 6129,
          49: 5989,
          50: 5855,
          51: 5726,
          52: 5602,
          53: 5483,
          54: 5369,
          55: 5259,
          56: 5152,
          57: 5050
        }
      },
      {
        radius: 55.0,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 12000,
          24: 12000,
          25: 12000,
          26: 12000,
          27: 11808,
          28: 11353,
          29: 10930,
          30: 10535,
          31: 10167,
          32: 9822,
          33: 9499,
          34: 9195,
          35: 8909,
          36: 8639,
          37: 8384,
          38: 8143,
          39: 7914,
          40: 7697,
          41: 7490,
          42: 7294,
          43: 7107,
          44: 6929,
          45: 6758,
          46: 6595,
          47: 6440,
          48: 6290,
          49: 6147,
          50: 6010,
          51: 5878,
          52: 5752,
          53: 5630,
          54: 5513,
          55: 5400
        }
      },
      {
        radius: 52.5,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 12000,
          24: 12000,
          25: 12000,
          26: 12000,
          27: 11809,
          28: 11355,
          29: 10932,
          30: 10539,
          31: 10172,
          32: 9828,
          33: 9505,
          34: 9202,
          35: 8916,
          36: 8647,
          37: 8392,
          38: 8151,
          39: 7922,
          40: 7705,
          41: 7499,
          42: 7303,
          43: 7116,
          44: 6938,
          45: 6768,
          46: 6605,
          47: 6449,
          48: 6300,
          49: 6157,
          50: 6020,
          51: 5888,
          52: 5761
        }
      },
      {
        radius: 50.0,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 12000,
          24: 12000,
          25: 12000,
          26: 12000,
          27: 12000,
          28: 11634,
          29: 11206,
          30: 10806,
          31: 10433,
          32: 10083,
          33: 9755,
          34: 9446,
          35: 9155,
          36: 8881,
          37: 8621,
          38: 8376,
          39: 8143,
          40: 7921,
          41: 7711,
          42: 7511,
          43: 7320,
          44: 7138,
          45: 6964,
          46: 6798,
          47: 6639,
          48: 6486,
          49: 6340,
          50: 6200
        }
      },
      {
        radius: 47.5,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 12000,
          24: 12000,
          25: 12000,
          26: 12000,
          27: 12000,
          28: 11639,
          29: 11215,
          30: 10820,
          31: 10450,
          32: 10103,
          33: 9778,
          34: 9471,
          35: 9182,
          36: 8910,
          37: 8652,
          38: 8407,
          39: 8176,
          40: 7955,
          41: 7746,
          42: 7547,
          43: 7356,
          44: 7175,
          45: 7002,
          46: 6836,
          47: 6677
        }
      },
      {
        radius: 45.0,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 12000,
          24: 12000,
          25: 12000,
          26: 12000,
          27: 12000,
          28: 12000,
          29: 11560,
          30: 11150,
          31: 10766,
          32: 10407,
          33: 10070,
          34: 9753,
          35: 9454,
          36: 9171,
          37: 8905,
          38: 8652,
          39: 8412,
          40: 8185,
          41: 7968,
          42: 7763,
          43: 7566,
          44: 7379,
          45: 7200
        }
      },
      {
        radius: 42.5,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 12000,
          24: 12000,
          25: 12000,
          26: 12000,
          27: 12000,
          28: 12000,
          29: 11566,
          30: 11160,
          31: 10781,
          32: 10425,
          33: 10091,
          34: 9776,
          35: 9480,
          36: 9200,
          37: 8935,
          38: 8684,
          39: 8445,
          40: 8219,
          41: 8004,
          42: 7799
        }
      },
      {
        radius: 40.0,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 12000,
          24: 12000,
          25: 12000,
          26: 12000,
          27: 12000,
          28: 12000,
          29: 11735,
          30: 11317,
          31: 10926,
          32: 10561,
          33: 10217,
          34: 9894,
          35: 9590,
          36: 9303,
          37: 9032,
          38: 8775,
          39: 8531,
          40: 8300
        }
      },
      {
        radius: 37.2,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 12000,
          24: 12000,
          25: 12000,
          26: 12000,
          27: 12000,
          28: 12000,
          29: 11577,
          30: 11182,
          31: 10812,
          32: 10464,
          33: 10137,
          34: 9828,
          35: 9537,
          36: 9261,
          37: 9001
        }
      },
      {
        radius: 34.7,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 12000,
          24: 12000,
          25: 12000,
          26: 12000,
          27: 12000,
          28: 12000,
          29: 11551,
          30: 11133,
          31: 10743,
          32: 10378,
          33: 10036,
          34: 9714
        }
      },
      {
        radius: 32.2,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 12000,
          24: 12000,
          25: 12000,
          26: 12000,
          27: 12000,
          28: 12000,
          29: 11549,
          30: 11129,
          31: 10737,
          32: 10370
        }
      },
      {
        radius: 29.7,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 12000,
          24: 12000,
          25: 12000,
          26: 12000,
          27: 12000,
          28: 12000,
          29: 11517
        }
      },
      {
        radius: 26.9,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 12000,
          24: 12000,
          25: 12000,
          26: 12000
        }
      },
      {
        radius: 24.4,
        capacityCurve: {
          1: 12000,
          2: 12000,
          3: 12000,
          4: 12000,
          5: 12000,
          6: 12000,
          7: 12000,
          8: 12000,
          9: 12000,
          10: 12000,
          11: 12000,
          12: 12000,
          13: 12000,
          14: 12000,
          15: 12000,
          16: 12000,
          17: 12000,
          18: 12000,
          19: 12000,
          20: 12000,
          21: 12000,
          22: 12000,
          23: 12000,
          24: 12000
        }
      }
    ]
  }
];

const towers = ['21 HC 290'];

const craneTypes = [
  {
    name: '280 EC-H 12 Litronic',
    category: 'High-Top',
    type: 'ech',
    lines: 2,
    hoistGear: '45 kW FU',
    slewingGear: '2 x 7,5 kW FU',
    trolleyGear: '7,5 kW FU',
    tower: '21 HC 290',
    norm: 'EN 14439',
    maxCapacity: 12000,
    capacityAtMax: 2500,
    minRadius: 2.3,
    maxRadius: 75.0,
    // height: 64.4,
    maxHeight: 64.4
  },
  {
    name: '370 EC-B 12 Fibre',
    category: 'High-Top',
    type: 'ecb',
    lines: 2,
    hoistGear: '45 kW FU',
    slewingGear: '2 x 7,5 kW FU',
    trolleyGear: '7,5 kW FU',
    tower: '21 HC 290',
    norm: 'EN 14439',
    maxCapacity: 12000,
    capacityAtMax: 2500,
    minRadius: 2.6,
    maxRadius: 78.0,
    // height: 70.6,
    maxHeight: 70.6
  }
];

const cranes = [];
let id = 0;

for (let i = 0; i < craneTypes.length; ++i) {
  const craneCurves = curves.find(x => x.name === craneTypes[i].name).curves;
  const craneBases = bases.filter(x => x.type === craneTypes[i].name);
  for (let j = 0; j < craneBases.length; ++j) {
    for (let k = 0; k < craneCurves.length; ++k) {
      for (let l = 0; l < towers.length; ++l) {
        for (let m = 0; m < craneBases[j].heights.length; ++m) {
          cranes.push({
            id: id,
            ...craneTypes[i],
            base: craneBases[j].name,
            height: craneBases[j].heights[m],
            tower: towers[l],
            ...craneCurves[k],
            capacityAtRadius: Object.values(craneCurves[k].capacityCurve).slice(
              -1
            )[0]
          });
          id++;
        }
      }
    }
  }
}

export { cranes };
