const jsonResponseType = 'application/json';

const handleErrors = async response => {
  if (!response.ok) {
    throw Error(`${response.status}: ${response.statusText}`);
  }

  return response;
};

export const postDownloadRequestApiKey = (apiKey, url, body) => {
  return fetch(url, {
    method: 'post',
    headers: {
      Accept: 'application/octet-stream',
      'X-API-Key': apiKey,
      'Content-Type': jsonResponseType
    },
    mode: 'cors',
    body: body
  })
    .then(handleErrors)
    .then(response => response.blob())
    .then(blob => URL.createObjectURL(blob));
};

export const postModelFileNameRequestApiKey = (apiKey, url, body) => {
  return fetch(url, {
    method: 'post',
    headers: {
      Accept: jsonResponseType,
      'X-API-Key': apiKey,
      'Content-Type': jsonResponseType
    },
    mode: 'cors',
    body: body
  })
    .then(handleErrors)
    .then(response => response.text())
};
