import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Button } from '@lbc-toolkit/button';
import { Grid, GridItem } from '@lbc-toolkit/grid';
import { Flex, FlexItem } from '@lbc-toolkit/flex';
import { List } from '@lbc-toolkit/list';
import { Text } from '@lbc-toolkit/text';
import { faPen, faDownload } from '@fortawesome/pro-solid-svg-icons';
import DownloadButtons from './DownloadButtons';
import CapacityCurveChart from './CapacityCurveChart';

const propTypes = {
  crane: PropTypes.object.isRequired,
  takeoverUrl: PropTypes.string
};

const defaultProps = {
  takeoverUrl: undefined
}

function DetailArea({ crane, takeoverUrl }) {
  const configDetails = [
    {
      label: 'Last',
      value: `${(crane.maxCapacity / 1000).toFixed(1)} t`
    },
    { label: 'Ausladung', value: `${crane.radius} m` },
    {
      label: 'Last bei max. Ausladung',
      value: `${(crane.capacityAtRadius / 1000).toFixed(1)} t`
    },
    { label: 'Hakenhöhe', value: `${crane.height} m` },
    { label: 'Turmaufbau', value: crane.tower },
    { label: 'Kranbasistyp', value: crane.base }
  ];
  const technicalDetails1 = [
    {
      label: 'Max. Last',
      value: `${(crane.maxCapacity / 1000).toFixed(1)} t`
    },
    { label: 'Max. Ausladung', value: `${crane.maxRadius} m` },
    {
      label: 'Max. Last bei max. Ausladung',
      value: `${(crane.capacityAtMax / 1000).toFixed(1)} t`
    },
    { label: 'Max. Hakenhöhe', value: `${crane.maxHeight} m` },
    { label: 'Norm', value: crane.norm }
  ];

  const technicalDetails2 = [
    { label: 'Stränge', value: crane.lines },
    { label: 'Hubwerk', value: crane.hoistGear },
    { label: 'Drehwerk', value: crane.slewingGear },
    { label: 'Katzfahrwerk', value: crane.trolleyGear }
  ];

  return (
    <Grid
      columns="1fr 1fr"
      rows="auto auto 1fr auto 1fr"
      padding="24px 0px"
      gap="32px"
    >
      <GridItem>
        <Text type="h3">{crane.name}</Text>
        <Text type="p">{crane.category}</Text>
      </GridItem>
      <GridItem>
        <DownloadButtons crane={crane} takeoverUrl={takeoverUrl}/>
      </GridItem>
      <GridItem>
        <Flex alignItems="center" justifyContent="space-between">
          <FlexItem>
            <Text type="h4">Vorgeschlagene Konfiguration</Text>
          </FlexItem>
          <FlexItem>
            <Button type="radial" icon={faPen} />
          </FlexItem>
        </Flex>
      </GridItem>
      <GridItem />
      <GridItem>
        <List data={configDetails} itemHeight={48} />
      </GridItem>
      <GridItem textAlign="center">
        <Text type="h6">Last und Ausladung</Text>
        <CapacityCurveChart data={crane.capacityCurve} />
      </GridItem>
      <GridItem>
        <Text type="h4">Technische Daten</Text>
      </GridItem>
      <GridItem justifySelf="end">
        <Button type="radial" icon={faDownload} />
      </GridItem>
      <GridItem>
        <List data={technicalDetails1} itemHeight={48} />
      </GridItem>
      <GridItem>
        <List data={technicalDetails2} itemHeight={48} />
      </GridItem>
    </Grid>
  );
}

DetailArea.propTypes = exact(propTypes);

DetailArea.defaultProps = defaultProps;

export default DetailArea;
