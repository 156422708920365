import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { Navbar } from '@lbc-toolkit/navbar';
import AccessDenied from './common/AccessDenied';
import CraneDetailsPage from './craneDetailsPage/pages/CraneDetailsPage';
import HomePage from './homepage/pages/HomePage';
import Login from './auth/Login';
import NewConfigPage from './newConfigPage/pages/NewConfigPage';
import MyCranesPage from './myCranesPage/pages/MyCranesPage';
import SmartFilterPage from './smartFilterPage/pages/SmartFilterPage';
import {
  navigateToHome,
  navigateToStart,
  navigateToMyCranes
} from '../redux/menuItem/actions';
import { activeMenuId } from '../redux/menuItem/selectors';
import {
  hasAccess,
  userInitials as initials,
  userName as name
} from '../redux/user/selectors';
import styles from './App.module.css';
import { NAVIGATION } from 'consts/navigation';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';

const propTypes = {
  manager: PropTypes.object,
  user: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  staticContext: PropTypes.object
};

const defaultProps = {
  user: undefined,
  manager: {},
  match: {},
  history: {},
  location: {},
  staticContext: {}
};

function App({ manager, user }) {
  const dispatch = useDispatch();
  const currentlyActiveMenuId = useSelector(state => activeMenuId(state));
  const accessGranted = useSelector(state => hasAccess(state));
  const userInitials = useSelector(state => initials(state));
  const userName = useSelector(state => name(state));
  let history = useHistory();

  const menuData = [
    NAVIGATION.main,
    NAVIGATION.newConfiguration,
    NAVIGATION.myCranes
  ];

  function handleMenuClick(id) {
    switch (id) {
      case 0:
        history.push(NAVIGATION.main.routes.ROOT);
        dispatch(navigateToHome());
        break;
      case 1:
        history.push(NAVIGATION.newConfiguration.routes.START);
        dispatch(navigateToStart());
        break;
      case 2:
        history.push(NAVIGATION.myCranes.routes.MYCRANES);
        dispatch(navigateToMyCranes());
        break;
      default:
        break;
    }
  }

  if (!user) {
    return <Login manager={manager} />;
  }

  if (user && !accessGranted) {
    return <AccessDenied />;
  }

  return (
    <div>
      {user && accessGranted && (
        <>
          <Navbar
            data={menuData}
            avatarAbbreviation={userInitials}
            avatarLabel={userName}
            selectedItemId={currentlyActiveMenuId}
            onSelectItem={handleMenuClick}
          />

          <div className={styles.content}>
            <Route
              exact
              path={NAVIGATION.main.routes.ROOT}
              component={HomePage}
            />
            <Route
              exact
              path={NAVIGATION.newConfiguration.routes.START}
              component={NewConfigPage}
            />
            <Route
              exact
              path={NAVIGATION.newConfiguration.routes.SMART}
              component={SmartFilterPage}
            />
            <Route
              exact
              path={NAVIGATION.myCranes.routes.MYCRANES}
              component={MyCranesPage}
            />
            <Route
              exact
              path={NAVIGATION.myCranes.routes.CRANEDETAILS}
              component={CraneDetailsPage}
            />
          </div>
        </>
      )}
    </div>
  );
}

App.propTypes = exact(propTypes);
App.defaultProps = defaultProps;

export default App;
