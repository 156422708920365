import { faFolder, faPlus } from '@fortawesome/pro-solid-svg-icons';

export const NAVIGATION = {
  main: {
    mainIcon: 'CC',
    label: 'Crane Configurator',
    routes: {
      ROOT: '/'
    },
    routeId: 0
  },
  newConfiguration: {
    icon: faPlus,
    label: 'Neue Krankonfiguration',
    routes: {
      START: '/start',
      SMART: '/smart'
    },
    routeId: 1
  },
  myCranes: {
    icon: faFolder,
    label: 'Meine Krane',
    routes: {
      MYCRANES: '/mycranes',
      CRANEDETAILS: '/cranedetails'
    },
    routeId: 2
  }
};
