import React from 'react';
import { Grid, GridItem } from '@lbc-toolkit/grid';
import logo from '../../../media/images/logo.png';
import styles from './Logo.module.css';

function Logo() {
  return (
    <Grid padding="24px" justifyContent="end">
      <GridItem>
        <img alt="Liebherr Logo" src={logo} className={styles.logo} />
      </GridItem>
    </Grid>
  );
}

export default Logo;
