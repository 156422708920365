import * as types from './types';

const CAPACITY_TOLERANCE = 1.15;

export const isLoading = state => state.cranes.status === types.CRANES_LOADING;
export const cranes = state => state.cranes.cranes;

const capacityCriteria = (crane, filters) =>
  filters.minCapacity >= 0 &&
  filters.maxCapacity >= 0 &&
  crane.maxCapacity >= filters.minCapacity * 1000 &&
  crane.maxCapacity <= filters.maxCapacity * CAPACITY_TOLERANCE * 1000;

const radiusCriteria = (crane, filters) =>
  filters.maxRadius >= 0 &&
  crane.radius >= filters.maxRadius - 5 &&
  crane.radius <= filters.maxRadius + 5 &&
  crane.minRadius <= filters.maxRadius;

const findCapacityForRadius = (crane, radius) => {
  return crane.capacityCurve[radius];
};

const atMaxCriteria = (crane, filters) =>
  filters.maxCapacityAtMax.radius >= 0 &&
  filters.maxCapacityAtMax.capacity >= 0 &&
  findCapacityForRadius(crane, filters.maxCapacityAtMax.radius) >=
    filters.maxCapacityAtMax.capacity * 1000;

const heightCriteria = (crane, filters) =>
  filters.maxHeight >= 0 &&
  filters.maxHeight + 10 >= crane.height &&
  crane.height >= filters.maxHeight;

const baseCriteria = (crane, filters) => {
  if (filters.base.length < 1) {
    return true;
  }

  return filters.base.indexOf(crane.base) > -1;
};

const craneTypeCriteria = (crane, filters) => {
  if (filters.craneType.length < 1) {
    return true;
  }

  return filters.craneType.indexOf(crane.type) > -1;
};

const towerCriteria = (crane, filters) => {
  if (filters.tower.length < 1) {
    return true;
  }

  return filters.tower.indexOf(crane.tower) > -1;
};

const craneSorting = (a, b) => {
  return a.score - b.score;
};

const scoreHeight = (height, filteredHeight) =>
  Math.abs(height - filteredHeight) / height;

const scoreCapacity = (capacity, filteredCapacity) =>
  Math.abs(capacity - filteredCapacity) / capacity;

const scoreRadius = (radius, filteredRadius) =>
  Math.abs(radius - filteredRadius) / radius;

const scoreAtMax = (crane, filteredRadius, filteredCapacity) => {
  const capacity = findCapacityForRadius(crane, filteredRadius);
  return Math.abs(capacity - filteredCapacity) / capacity;
};

const scoreCranes = (cranes, filters) => {
  return cranes.map(crane => {
    let score = 0;
    score += scoreHeight(crane.height, filters.maxHeight);
    score += scoreCapacity(crane.maxCapacity, filters.maxCapacity);
    score += scoreRadius(crane.radius, filters.maxRadius);
    score += scoreAtMax(
      crane,
      filters.maxCapacityAtMax.radius,
      filters.maxCapacityAtMax.capacity * 1000
    );

    return { ...crane, score: score };
  });
};

export const filteredCranes = state => {
  const cranes = state.cranes.cranes;

  const filters = state.craneFilter;

  const filteredCranes = cranes.filter(
    crane =>
      heightCriteria(crane, filters) &&
      capacityCriteria(crane, filters) &&
      radiusCriteria(crane, filters) &&
      atMaxCriteria(crane, filters) &&
      baseCriteria(crane, filters) &&
      craneTypeCriteria(crane, filters) &&
      towerCriteria(crane, filters)
  );

  const scoredCranes = scoreCranes(filteredCranes, filters);

  scoredCranes.sort(craneSorting);

  return scoredCranes;
};
