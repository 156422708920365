import React, { useState } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Flex, FlexItem } from '@lbc-toolkit/flex';
import { Grid, GridItem } from '@lbc-toolkit/grid';
import { CraneConfiguratorCard } from '@lbc-toolkit/craneconfiguratorcard';
import { Modal } from '@lbc-toolkit/modal';
import { TextInput } from '@lbc-toolkit/textinput';
import { Tag } from '@lbc-toolkit/tag';
import CraneCardsTitle from './CraneCardsTitle';
import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { updateCraneTags } from '../../redux/myCranes/actions';
import ecbImage from '../../media/images/370EC-B.png';
import echImage from '../../media/images/280EC-H.png';
import { useDispatch, useSelector } from 'react-redux';
import { cranes as selectedCranes} from 'redux/selectedCranes/selectors';

const createCraneData = crane => {
  return [
    { label: 'Last', value: `${(crane.maxCapacity / 1000).toFixed(1)} t` },
    { label: 'Ausladung', value: `${crane.radius} m` },
    {
      label: 'Last bei max. Ausladung',
      value: `${(crane.capacityAtRadius / 1000).toFixed(1)} t`
    },
    { label: 'Hakenhöhe', value: `${crane.height.toFixed(1)} m` },
    { label: 'Turmaufbau', value: crane.tower },
    { label: 'Kranbasistyp', value: crane.base }
  ];
};

const propTypes = {
  title: PropTypes.string,
  cranes: PropTypes.array,
  onSelect: PropTypes.func,
  selectable: PropTypes.bool
};

const defaultProps = {
  title: '',
  cranes: [],
  onSelect: () => {},
  selectable: false,
};

function CraneCards({
  title,
  cranes,
  onSelect,
  selectable
}) {
  const dispatch = useDispatch();
  const currentlySelectedCranes = useSelector(state => selectedCranes(state));
  const [showTagEdit, setShowTagEdit] = useState(false);
  const [tags, setTags] = useState('');
  const [activeCrane, setActiveCrane] = useState({});

  const handleModalClose = () => {
    setShowTagEdit(false);
    setTags('');
  };

  const handleModalSave = () => {
    const tagList = tags.length > 0 ? tags.split(';') : [];

    dispatch(updateCraneTags(activeCrane, tagList));
    setShowTagEdit(false);
    setTags('');
    setActiveCrane({});
  };

  const modal = (
    <Modal
      show={showTagEdit}
      marginTop="100px"
      title="Stichworte anpassen"
      confirmButtonLabel="Übernehmen"
      cancelButtonLabel="Abbrechen"
      confirmButton={handleModalSave}
      closeIconButton={handleModalClose}
      cancelButton={handleModalClose}
    >
      <TextInput
        label="Stichworte"
        value={tags}
        width="100%"
        onChange={value => setTags(value)}
      />
    </Modal>
  );

  const showPopup = (crane, tagList) => {
    setActiveCrane(crane);
    setTags(tagList.join(';'));
    setShowTagEdit(true);
  };

  const cards = cranes.map(crane => {
    const tags = crane.tags ? (
      <Flex flow="row wrap">
        {crane.tags.length > 0 &&
          crane.tags.map(tag => (
            <FlexItem margin="0px 8px 0px 0px">
              <Tag
                label={tag}
                type="editable"
                height="24px"
                icon={faPen}
                onClick={() => showPopup(crane, crane.tags)}
              />
            </FlexItem>
          ))}
        {crane.tags.length < 1 && (
          <Tag
            label="Stichworte hinzufügen"
            onClick={() => showPopup(crane, crane.tags)}
            height="24px"
          />
        )}
      </Flex>
    ) : (
      <></>
    );
    return (
      <FlexItem key={crane.id} margin="0px 24px 24px 0px">
        <CraneConfiguratorCard
          image={crane.name.includes('280') ? echImage : ecbImage}
          modelName={crane.name}
          craneType={crane.category}
          listData={createCraneData(crane)}
          onClick={selected => onSelect(crane, selected)}
          selectable={selectable}
          // there is a bug, component has to be fixed so the selected state can be controlled from outside
          defaultSelected={
            currentlySelectedCranes.findIndex(c => c.id === crane.id) > -1
          }
          tag={tags}
        />
      </FlexItem>
    );
  });

  return (
    <Grid columns="repeat(4, 1fr)" gap="24px">
      {title && (
        <GridItem area="1/1/1/5">
          <CraneCardsTitle title={title} showAll={cranes.length > 0} />
        </GridItem>
      )}
      <GridItem area="2/1/2/5">
        <Flex flow="row wrap" width="100%">
          {cards}
        </Flex>
      </GridItem>
      {modal}
    </Grid>
  );
}

CraneCards.propTypes = exact(propTypes);

CraneCards.defaultProps = defaultProps;

export default CraneCards;
