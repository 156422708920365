import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Button } from '@lbc-toolkit/button';
import { Grid, GridItem } from '@lbc-toolkit/grid';
import { Text } from '@lbc-toolkit/text';
import Spacer from '../common/Spacer';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';

const propTypes = {
  title: PropTypes.string.isRequired
};

function FilterAreaHead({ title }) {
  return (
    <Grid columns="1fr 1fr" padding="24px 0">
      <GridItem>
        <Text type="h2">{title}</Text>
      </GridItem>
      <GridItem>
        <div style={{ display: 'flex', float: 'right' }}>
          <Button
            type="secondary"
            label="Filter zurücksetzen"
            width="175px"
            disabled
          />
          <Spacer />
          <Button type="radial" icon={faQuestionCircle} />
        </div>
      </GridItem>
    </Grid>
  );
}

FilterAreaHead.propTypes = exact(propTypes);

export default FilterAreaHead;
