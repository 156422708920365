import React from 'react';
import { useLocation } from 'react-router';

export const useQuery = () => {
  const { search } = useLocation();
  const urlParams = React.useMemo(() => new URLSearchParams(search), [search]);
  const caseInsensitiveUrlParams = new URLSearchParams();
  for (const [name, value] of urlParams) {
    caseInsensitiveUrlParams.append(name.toLowerCase(), value);
  }
  return caseInsensitiveUrlParams;
};

export default useQuery;
