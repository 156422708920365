import * as types from './types';

export const navigateToHome = () => ({
  type: types.NAVIGATE_TO_HOME
});

export const navigateToStart = () => ({
  type: types.NAVIGATE_TO_CONFIG_START
});

export const navigateToSmart = () => ({
  type: types.NAVIGATE_TO_SMART_CONFIG
});

export const navigateToMyCranes = () => ({
  type: types.NAVIGATE_TO_MYCRANES
});

export const navigateToDetails = () => ({
  type: types.NAVIGATE_TO_CRANE_DETAILS
});
