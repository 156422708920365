import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Link } from 'react-router-dom';
import { Grid, GridItem } from '@lbc-toolkit/grid';
import { Flex, FlexItem } from '@lbc-toolkit/flex';
import { Icon } from '@lbc-toolkit/icon';
import { Text } from '@lbc-toolkit/text';
import {
  faArrowLeft,
  faQuestionCircle
} from '@fortawesome/pro-solid-svg-icons';
import { Button } from '@lbc-toolkit/button';

const propTypes = {
  title: PropTypes.string.isRequired
};

function CraneDetailsPageTitle({ title }) {
  return (
    <Grid columns="auto 1fr auto" padding="24px 0">
      <GridItem>
        <Flex alignItems="center">
          <FlexItem>
            <Link to="/mycranes">
              <Icon icon={faArrowLeft} color="#333333" />
            </Link>
          </FlexItem>
          <FlexItem>
            <div style={{ width: '8px' }} />
          </FlexItem>
          <FlexItem>
            <Text type="h2" color="#C7C6C5">
              Meine Krane
            </Text>
          </FlexItem>
          <FlexItem>
            <div style={{ width: '8px' }} />
          </FlexItem>
          <FlexItem>
            <Text type="h2" color="#C7C6C5">
              /
            </Text>
          </FlexItem>
          <FlexItem>
            <div style={{ width: '8px' }} />
          </FlexItem>
          <FlexItem>
            <Text type="h2">{title}</Text>
          </FlexItem>
        </Flex>
      </GridItem>
      <GridItem />
      <GridItem>
        <Button type="radial" icon={faQuestionCircle} />
      </GridItem>
    </Grid>
  );
}

CraneDetailsPageTitle.propTypes = exact(propTypes);

export default CraneDetailsPageTitle;
