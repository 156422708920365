import * as types from './types';
import { cranes } from '../../tools/mockData';

const loadCranesLoading = () => ({
  type: types.CRANES_LOADING
});

const loadCranesSuccess = cranes => ({
  type: types.CRANES_SUCCESS,
  payload: cranes
});

// const loadCranesFailure = () => ({
//     type: types.CRANES_FAILURE
// });

const addCraneSuccess = crane => ({
  type: types.CRANES_ADD,
  payload: crane
});

const updateCraneTagsSuccess = crane => ({
  type: types.CRANES_UPDATE_TAGS,
  payload: crane
});

export const loadCranes = () => {
  return dispatch => {
    dispatch(loadCranesLoading());
    const myCranes = [cranes[33], cranes[373], cranes[489], cranes[841]];

    dispatch(loadCranesSuccess(myCranes));
    return myCranes;
  };
};

export const addCrane = (crane, tags) => {
  return dispatch => {
    const taggedCrane = { ...crane, tags: tags };
    dispatch(loadCranesLoading());
    dispatch(addCraneSuccess(taggedCrane));
  };
};

export const updateCraneTags = (crane, tags) => {
  return dispatch => {
    const taggedCrane = { ...crane, tags: tags };
    dispatch(updateCraneTagsSuccess(taggedCrane));
  };
};
